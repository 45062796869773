import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, delay } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Router,ActivatedRoute } from '@angular/router';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  imgPath = 'assets/images/';
  dummyDataUrl = '../../assets/data/data.json';
  textUrl = '../../assets/data/text.json';
  excelUrl = 'http://localhost:5000/read_excel';
  URL = environment.URL;
  CapexURL = environment.CapexURL;
  currentUser = localStorage.getItem('user') || '';
  userRole = localStorage.getItem('userRole') || '';
  amdUserRole = localStorage.getItem('amdUserRole') || '';
  capexUserRole = localStorage.getItem('capexUserRole') || '';

  assetFilter: any = null;
  importedAsset: any = null;
  displayMissingAsset: any = null;
  assetDetails: any;
  assetRequestChanges = '';

  //Cap --start

  CapUrl = environment.CapURL;
  porNumber: any;
  porStatus: any;

  _headrs=new HttpHeaders({'Content-Type': 'application/json'});

  public editDataDetails: any = [];
  private messageSource = new  BehaviorSubject(this.editDataDetails);
  documentData = this.messageSource.asObservable();

  public tabDetails: any = [];
  private tabDetailsFunc = new BehaviorSubject(this.tabDetails);
  tabDetailsData = this.tabDetailsFunc.asObservable();

  public tabDetails1: any = [];
  private tabDetailsFunc1 = new BehaviorSubject(this.tabDetails1);
  tabDetailsData1 = this.tabDetailsFunc1.asObservable();

  public focusAreaStatusDetails!:any;
  private focusAreaStatusFunc = new BehaviorSubject(this.focusAreaStatusDetails);
  focusAreaStatus = this.focusAreaStatusFunc.asObservable();

  public passDataDetails = [];
  private passDataFunc = new BehaviorSubject(this.passDataDetails);
  passData =this.passDataFunc.asObservable();

  //cap --end

  public token!: string;
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bannerData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public buildingData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentModule = new BehaviorSubject<any>([]);
  public loggedOutMsg: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userName!: string;
  private transferData = new BehaviorSubject<any>(null);
  public transferData$ = this.transferData.asObservable();
  public estimatedEndDate: BehaviorSubject<any> = new BehaviorSubject<any>({});
  //public adjustedWbsAmmount: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router, private activatedRoute: ActivatedRoute) { }

  getDummyData(): Observable<Object> {
    return this.http.get(this.dummyDataUrl);
  }
  getText(): Observable<Object> {
    return this.http.get(this.textUrl);
  }
  getExcelData(): Observable<Object> {
    return this.http.get(this.excelUrl);
  }

  getAPI(url: string): Observable<Object | any> {
    return this.http.get(this.URL + url);
  }

  postAPI(url: string, data: any): Observable<Object> {
    return this.http.post(this.URL + url, data);
  }
  
  postAssetRequestDetails(asset: any, RequetType: any): Observable<any> {
    console.log(RequetType);
    if (RequetType == 'Basic / Transfer') {
      this.assetRequestChanges = "amd-basic-request-change/";
    }

    if (RequetType == 'Retirement') {
      this.assetRequestChanges = "amd-request-retirement/";
    }
    return this.postAPI(this.assetRequestChanges, { "Data": asset }).pipe(delay(2000));
  }

  // signIn() {
  //   Auth.federatedSignIn();
  //  // Auth.federatedSignIn({ customProvider: environment.Auth.identityProvider });
  // }

  login() {
   // Auth.federatedSignIn();
    Auth.federatedSignIn({ customProvider: environment.Auth.identityProvider });
  }

  logout() {
    // localStorage.clear();
    // this.isUserLoggedIn.next(false);
    // this.router.navigateByUrl('login')
    Auth.signOut();
  }

  async userAuth() {
    if (!this.isUserLoggedIn.getValue()) {
      const logged = await this.checkLogin();
      return (logged);
    } else {
      return true;
    }
  }

  
  // checkLogin() {
  //   return new Promise<boolean>((resolve, rejects) => {
  //     const check = localStorage.getItem('user') ? true : false;
  //     environment.current_user_email = atob(this.currentUser);
  //     resolve(check);
  //   });
  // }

  checkLogin() {
    return new Promise<boolean>(async (resolve, rejects) => {
      try {
        const _currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        environment.current_user_email = _currentAuthenticatedUser.signInUserSession.idToken.payload.email;
        environment.token = this.token = _currentAuthenticatedUser.signInUserSession?.idToken.jwtToken;
        this.userName = environment.current_user_email;
        this.currentUser = btoa(environment.current_user_email);
        localStorage.setItem('user', btoa(environment.current_user_email));
        let returnUrl = localStorage.getItem('returnUrl');
        await this.checkUserRole(); // Wait for checkUserRole to complete
        setTimeout(() => {
          if (returnUrl != null) {
            localStorage.setItem('tokenValidity', 'renewed');
            localStorage.removeItem('returnUrl');
          }
        }, 500);
        resolve(true);
      } catch (error) {
        this.isUserLoggedIn.next(false);
        resolve(false);
      }
    });
  }

  checkUserRole() {
    return new Promise<void>((resolve, reject) => {
      this.getAPI('get-user-role/?email=' + this.userName).subscribe((data: any) => {
        if (data != undefined) {
          console.log("AMD UserRole" , data['AMD_Role'])
          // let activatedUrl= this.extract_word_from_url(this.router.url)
          // console.log("Activated URL ",activatedUrl)
          // this.userRole = activatedUrl === 'asset' ? data['AMD_Role'] : data['Capex_Role'];
          this.userRole = data['AMD_Role']
          this.amdUserRole = data['AMD_Role'];
          this.capexUserRole = data['Capex_Role'];
          localStorage.setItem('userRole',this.userRole);
          resolve();
        } else {
          reject(new Error('Something went wrong'));
        }
      });
    });
  }

  getUserRole() {
    // return this.userRole;
    let activatedUrl= this.extract_word_from_url(this.router.url)
    // console.log("Activated URL ",activatedUrl)
    if (activatedUrl === 'asset'){
      return this.amdUserRole;
    }
    else{
      return this.capexUserRole;
    }
  }

  extract_word_from_url(url: string) {
    let parts = url.split('/');
    for (let part of parts){
      if(part.includes("asset")) {
        return "asset";
      } else if (part.includes("capex")) {
        return "capex";
      }   
    }
      return null;
  }

  show(type: ToastType, title: string, body?: string, statusCode?: number) {

    if (statusCode !== undefined) {
      if (statusCode === 500) {
        this.toastr.error('Server communication error');
      } else if (statusCode === 0) {
        this.toastr.error('Something went wrong', 'Please contact admin');
      } else if (statusCode === 400) {
        this.toastr.info(title, '');
      }
      else if (statusCode === 451) {
        this.toastr.warning('No data found');
      }
      else if (statusCode === 403) {
        this.toastr.warning('Your session has expired', '');
        this.loggedOutMsg.next('Your session has expired');
        this.logout();
      } else if (statusCode === 401) {
        //this.toastr.error('Your session has expired', 'Please login again');
        this.toastr.error(body, title);
        this.loggedOutMsg.next('Your session has expired');
        this.logout();
      } 
      else {
        this.toastr.error(body, title);
      }
    } else {
      if (type === 'success') {
        this.toastr.success(body, title);
      } else if (type === 'info') {
        this.toastr.info(body, title);
      } else if (type === 'error') {
        this.toastr.error(body, title);
      } else if (type === 'warning') {
        this.toastr.warning(body, title.length > 0 ? title : 'Please contact admin')
      }
    }
  }

  getFilter(asset: any) {
    this.assetFilter = asset
  }

  setAssetData(assetData: any) {
    this.assetDetails = assetData;
  }

  getAssetData() {
    return this.assetDetails;
  }

  errorHandler(err: any) {
    if (err) {
      this.show('error', (err.error.message || err.message), 'Please contact admin', err.status)
    } else {
      this.show('warning', 'Something went wrong', 'Try to refresh or contact admin');
    }
    this.loader.next(false);
  }

  //Capex API
  getCapexAPI(url: string): Observable<Object | any> {
    return this.http.get(this.CapexURL + url);
  }

  postCapexAPI(url: string, data: any): Observable<Object | any> {
    return this.http.post(this.CapexURL + url, data);
  }

  putAPI(url: string, data: any): Observable<Object> {
    return this.http.put(this.CapexURL + url, data);
  }

  setData(value: any) {
    this.transferData.next(value);
  }

  //Cap

  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  focusAreaStatusFunction(message: any){
    this.focusAreaStatusFunc.next(message);
  }

  getDataFromDB(): Observable<any> {
    this.activatedRoute.queryParams.subscribe(params => {
     this.porStatus = params['porStatus']; 
     //this.porNumber = params['porNumber'];
    });
    this.porNumber = localStorage.getItem('por_number') ? localStorage.getItem('por_number') : '';
    let getDataFromDBUrl = 'itg/cap-fetch-data-db-itg?por_number='+ this.porNumber+'&'+"por_status="+this.porStatus;
    return this.getCapAPI(getDataFromDBUrl);
  }

  getCapAPI(appUrl:string):Observable<any>{
    //console.log(environment.CapURL+ appUrl)
    return this.http.get(this.CapUrl+ appUrl,{headers:this._headrs});
  }

  postCapAPI(url: string, data: any): Observable<Object | any> {
    return this.http.post(this.CapUrl + url, data);
  }

   //Use this method to pass function Data
   passDataFunction(message: any){
    this.passDataFunc.next(message);
  }

  getById(appUrl:string,Id:string):Observable<any>{
    //console.log(environment.URL+ appUrl)
    return this.http.get(this.URL+appUrl+Id,{headers:this._headrs});
  }

  getLabFabMatrix(){
    return this.http.get('assets/files/labFab_Matrix.csv', {responseType: 'text'})
  }

  changeTab1(message: any) {
    this.tabDetailsFunc.next(message)
  }

  changeTab2(message: any) {
    this.tabDetailsFunc1.next(message)
  }

  getQuarterFromDate(input: Date | string): string {
    let date: Date;
  
    // Parse input into a Date object
    if (typeof input === 'string') {
        const [month, day, year] = input.split('-').map(Number);
        date = new Date(year, month - 1, day); // month is zero-based
    } else {
        date = input;
    }
  
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const year = date.getFullYear();
    let quarter: number;
    let fiscalYear: number;
  
    if (month >= 1 && month <= 3) {
        quarter = 4;
        fiscalYear = year;
    } else if (month >= 4 && month <= 6) {
        quarter = 1;
        fiscalYear = year + 1;
    } else if (month >= 7 && month <= 9) {
        quarter = 2;
        fiscalYear = year + 1;
    } else {
        quarter = 3;
        fiscalYear = year + 1;
    }
  
    return `FY${fiscalYear.toString().slice(-2)} Q${quarter}`;
  }

}
