<div class="row">
    <div class="col-12">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event)" placeholder="Search">
        </mat-form-field>

        <!-- Cost center mangeres bulk uploads -->
        <div class="exportCCD" style="margin-left: 30px;" *ngIf="isCCActive">
            <button class="btn btn-primary" *ngIf="isadmin" (click)="Import_Bulk_Cost_Center_Code.click()">
                <i class="fa fa-upload fa-fade"></i> &nbsp;

                <ng-container *ngIf="loading">
                    <i class="fa fa-spinner fa-spin"></i>&nbsp;
                    {{progress}}% &nbsp;
                </ng-container>
                Import Bulk Cost Center Code
            </button>
            <input class="btn btn-primary" id="Import_Bulk_Cost_Center_Code" style="display: none" type="file"
                accept=".xlsx, .xls" multiple="false" (change)="importBulkCostManagers($event)"
                #Import_Bulk_Cost_Center_Code>&nbsp;&nbsp;
        </div>
        <div class="exportCCD" *ngIf="isCCActive">
            <app-export-bulk-data [Sheet_lineStart_Row]="'0'" [exportFileName]="'AMD_Bulk_Cost_Center_Owner'"
                [apiPath]="' '" [Sheet_Coloumns_Headers]="bulkCost_Center_Owner_Headers"
                [Sheet_rows_data]="costCenterData" [Button_Name]="'Export Bulk Cost Center Code'"
                [Sheet_Name]="'AMD Bulk Cost Center Code'"></app-export-bulk-data>
        </div>

        <div class="exportCCD" style="margin-left: 30px;" *ngIf="!isCCActive">
            <button class="btn btn-primary" *ngIf="isadmin" (click)="Import_Bulk_Program_Drivers.click()">
                <i class="fa fa-upload fa-fade"></i> &nbsp;

                <ng-container *ngIf="loading">
                    <i class="fa fa-spinner fa-spin"></i>&nbsp;
                    {{progress}}% &nbsp;
                </ng-container>
                Import Bulk Program Drivers
            </button>
            <input class="btn btn-primary" id="Import_Bulk_Program_Drivers" style="display: none" type="file"
                accept=".xlsx, .xls" multiple="false" (change)="importBulkProgramDrivers($event)"
                #Import_Bulk_Program_Drivers>&nbsp;&nbsp;
        </div>
        <div class="exportCCD" *ngIf="!isCCActive">
            <app-export-bulk-data [Sheet_lineStart_Row]="'0'" [exportFileName]="'AMD_Bulk_Program_Drivers'"
                [apiPath]="' '" [Sheet_Coloumns_Headers]="bulk_Program_Drivers_Headers"
                [Sheet_rows_data]="programDriverData" [Button_Name]="'Export Bulk Program Drivers'"
                [Sheet_Name]="'AMD Bulk Program Drivers'"></app-export-bulk-data>
        </div>

        <div class="exportCCD" *ngIf="!isCCActive">
            <button class="btn btn-primary" (click)="getCostCenter()">
                View Cost Center
            </button>
        </div>
        
            <div class="exportCCD" *ngIf="isCCActive">
                <button class="btn btn-primary" *ngIf="isadmin" (click)="addCostCenter()">
                    <i class="fa fa-plus"></i> 
                    Add Cost Center
                </button>
            </div>

            <div class="exportCCD" *ngIf="isCCActive">
                <button class="btn btn-primary" (click)="showProgramDriver()">
                    View Program Drivers
                </button>
            </div>
       
            <div class="exportCCD" *ngIf="!isCCActive">
                <button class="btn btn-primary" *ngIf="isadmin" (click)="addProDriver()">
                    <i class="fa fa-plus"></i> 
                    Program Drivers
                </button>
            </div>
    </div>

</div>
<div *ngIf="isCCActive" class="mat-elevation-z8 mb-5">
    <div class="table-responsive  mt-3">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="Edit">
                <th mat-header-cell *matHeaderCellDef>Edit</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <button class="btn btn-primary" (click)="edit(element, i)"><i class="fa fa-edit"></i></button>
                </td>
            </ng-container>
            <ng-container matColumnDef="CostCenterCode">
                <th mat-header-cell *matHeaderCellDef>Cost Center</th>
                <td mat-cell *matCellDef="let element"> {{element['Cost Center Code']}} </td>
            </ng-container>
            <ng-container matColumnDef="CostCenterOwner">
                <th mat-header-cell *matHeaderCellDef>Cost Center Manager</th>
                <td mat-cell *matCellDef="let element"> {{element['Cost Center Owner']}} </td>
            </ng-container>
            <ng-container matColumnDef="CostCenterDescription">
                <th mat-header-cell *matHeaderCellDef>Cost Center Description</th>
                <td mat-cell *matCellDef="let element"> {{element['Cost Center Description'] == "null" ? '':
                    element['Cost Center Description']}} </td>
            </ng-container>
            <ng-container matColumnDef="CompanyCode">
                <th mat-header-cell *matHeaderCellDef>Company Code</th>
                <td mat-cell *matCellDef="let element"> {{element['Company Code'] == "null" ? '': element['Company
                    Code']}} </td>
            </ng-container>
            <ng-container matColumnDef="CcName">
                <th mat-header-cell *matHeaderCellDef>Cost Center Name</th>
                <td mat-cell *matCellDef="let element"> {{element['Cost Center Name']}} </td>
            </ng-container>            
            <ng-container matColumnDef="Region">
                <th mat-header-cell *matHeaderCellDef>Region</th>
                <td mat-cell *matCellDef="let element"> {{element.Region}} </td>
            </ng-container>
            <ng-container matColumnDef="MRUCode">
                <th mat-header-cell *matHeaderCellDef>MRU</th>
                <td mat-cell *matCellDef="let element"> {{element['MRU Code']}} </td>
            </ng-container>
            <ng-container matColumnDef="SubMRU">
                <th mat-header-cell *matHeaderCellDef>Sub MRU</th>
                <td mat-cell *matCellDef="let element"> {{element['Sub_MRU']}} </td>
            </ng-container>
            <ng-container matColumnDef="Level2">
                <th mat-header-cell *matHeaderCellDef>Organization-Level 2</th>
                <td mat-cell *matCellDef="let element"> {{element['Level2']}} </td>
            </ng-container>
            <ng-container matColumnDef="Level3">
                <th mat-header-cell *matHeaderCellDef>Organization-Level 3</th>
                <td mat-cell *matCellDef="let element"> {{element['Level3']}} </td>
            </ng-container>
            <ng-container matColumnDef="Level4">
                <th mat-header-cell *matHeaderCellDef>Organization-Level 4</th>
                <td mat-cell *matCellDef="let element"> {{element['Level4']}} </td>
            </ng-container>
            <ng-container matColumnDef="Section">
                <th mat-header-cell *matHeaderCellDef>Section</th>
                <td mat-cell *matCellDef="let element"> {{element['Section']}} </td>
            </ng-container>
            <ng-container matColumnDef="ExpType">
                <th mat-header-cell *matHeaderCellDef>ExpType</th>
                <td mat-cell *matCellDef="let element"> {{element['ExpType']}} </td>
            </ng-container>
            <ng-container matColumnDef="Commit">
                <th mat-header-cell *matHeaderCellDef>Commit</th>
                <td mat-cell *matCellDef="let element"> {{element['Commit']}} </td>
            </ng-container>
            <ng-container matColumnDef="Depr">
                <th mat-header-cell *matHeaderCellDef>Depreciation</th>
                <td mat-cell *matCellDef="let element"> {{element['Depreciation']}} </td>
            </ng-container>
            <ng-container matColumnDef="Inactive">
                <th mat-header-cell *matHeaderCellDef>Inactive</th>
                <td mat-cell *matCellDef="let element"> {{element['Inactive']}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-primary text-white"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <p *ngIf="noRecordFound" class="text-center mt-3">No record found</p>
    <!-- <div class="loader p-5" *ngIf="loader">
      <mat-spinner></mat-spinner>
    </div> -->
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons> </mat-paginator>
</div>

<!-- Program Driver Table -->
<div *ngIf="!isCCActive" class="mat-elevation-z8 mb-5">
    <div class="table-responsive mt-3">
      <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="BusinessUnit">
          <th mat-header-cell *matHeaderCellDef>Business Unit</th>
          <td mat-cell *matCellDef="let element"> {{element.BusinessUnit}} </td>
        </ng-container>
        <ng-container matColumnDef="Category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let element"> {{element.Category}} </td>
          </ng-container>
        <ng-container matColumnDef="EngrNRELoc">
          <th mat-header-cell *matHeaderCellDef>Engr NRE Loc</th>
          <td mat-cell *matCellDef="let element"> {{element.EngrNRELoc}} </td>
        </ng-container>
        <ng-container matColumnDef="ProgramDriver">
            <th mat-header-cell *matHeaderCellDef>Program Driver</th>
            <td mat-cell *matCellDef="let element"> {{element.ProgramDriver}} </td>
          </ng-container>
        <ng-container matColumnDef="RollUp">
          <th mat-header-cell *matHeaderCellDef>Roll Up</th>
          <td mat-cell *matCellDef="let element"> {{element.RollUp}} </td>
        </ng-container>
        <ng-container matColumnDef="Inactive">
          <th mat-header-cell *matHeaderCellDef>Inactive</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <button class="btn-active"  (click)="toggleInactive(element, i)">
                {{element.Inactive}}
              </button>
        </td>
        </ng-container>
        <ng-container matColumnDef="Level3">
          <th mat-header-cell *matHeaderCellDef>Level 3</th>
          <td mat-cell *matCellDef="let element"> {{element.Level3}} </td>
        </ng-container>
        <ng-container matColumnDef="TechNRELoc">
            <th mat-header-cell *matHeaderCellDef>Tech NRE Loc</th>
            <td mat-cell *matCellDef="let element"> {{element.TechNRELoc}} </td>
          </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="text-primary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <p *ngIf="noRecordFound" class="text-center mt-3">No record found</p>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>