import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'wbs-cashflow-details',
  templateUrl: './wbs-cashflow-details.component.html',
  styleUrls: ['./wbs-cashflow-details.component.scss'],
})
export class WbsCashflowDetailsComponent {
  wbsCashFlowDetailsForm: FormGroup;

  userRole = this.common.getUserRole();
  isRequester: boolean = this.userRole === 'Requester';

  constructor(
    private common: CommonService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    this.wbsCashFlowDetailsForm = this.fb.group({
      purchaseOrders: this.fb.array([]),
      journalVouchers: this.fb.array([]),
      invoices: this.fb.array([]),
    });
  }

  capexFormData: any;

  ngOnInit() {
    // Populate form arrays
    // this.addFormGroups(this.purchaseOrders, 'purchaseOrders');
    // this.addFormGroups(this.journalVouchers, 'journalVouchers');
    // this.addFormGroups(this.invoices, 'invoices');

    // Disable the form if the user is a requester
    if (this.isRequester) {
      this.wbsCashFlowDetailsForm.disable();
    }
  }

  addFormGroups(data: any[], arrayName: string) {
    const formArray = this.wbsCashFlowDetailsForm.get(arrayName) as FormArray;
    data.forEach(() => {
      formArray.push(this.fb.group({}));
    });
  }
}
