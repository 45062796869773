import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'wbs-cashflow-summary',
  templateUrl: './wbs-cashflow-summary.component.html',
  styleUrls: ['./wbs-cashflow-summary.component.scss']
})
export class WbsCashFlowComponent {
  wbsCashFlowForm: FormGroup = this.fb.group({
    estimatedTotalCost: [{value: '', disabled: true}],
    currentWbsAmount: [{value: '', disabled: true}],
    totalReconcillation: [''],
    approvedPoCommits: [''],
    totalInvoicedPo: [''],
    totalAmountJvs: [''],
    TotalCashFlow: [''],
    estimatedTotalSpend: [''],
    estimatedRemainingSpend: [''],
    monthsStatic: [''],
    engrNreCost: [{value: '', disabled: true}],
    techNreCost: [{value: '', disabled: true}],
    totalNreCost: [{value: '', disabled: true}],
    engrNreBilled: [''],
    techNreBilled: [''],
    totalNreBilled: [''],
    remainingNre: [''],
    hookupCapitalized: [''],
    hookupBiiled: [''],
    remainingHookup: [''],
  });

  userRole = this.common.getUserRole();
  isRequester: boolean = this.userRole === 'Requester'? true : false;
  wbs: any;


  constructor(private common: CommonService, private fb: FormBuilder, 
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {
    }

    capexFormData: any;

    ngOnInit(){
      if(this.isRequester){
        this.wbsCashFlowForm.disable();
      }
    }

    accessWbsCashFlow(data: any){
      this.capexFormData = data;
      this.wbs = this.capexFormData?.wbsDetailsForm?.value?.wbs;
      console.log(data);
     // let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost);
      let totalCost = Number(this.capexFormData?.projectCostDetails?.itemCost || 0) + Number(this.capexFormData?.projectCostDetails?.hookCapitalCost || 0) + Number(this.capexFormData?.NREtotalCost || 0);
      let engrCost = Number(this.capexFormData?.projectCostDetails?.engrNRECost || 0);
      let techCost = Number(this.capexFormData?.projectCostDetails?.techNRECost || 0);
      let totalNre = engrCost + techCost;
      //this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue(totalCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['estimatedTotalCost']?.setValue((Math.round(totalCost)).toFixed(2),{ emitEvent: false });
      this.wbsCashFlowForm.controls['engrNreCost']?.setValue(engrCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['techNreCost']?.setValue(techCost, { emitEvent: false });
      this.wbsCashFlowForm.controls['totalNreCost']?.setValue(totalNre, { emitEvent: false });
      //this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(totalCost, { emitEvent: false });
     // const value = this.common.adjustedWbsAmmount?.getValue();
      if(this.wbs){
     this.common.getCapexAPI('ltp-por/get-wbs-amount-from-reports?wbs=' + this.wbs).subscribe({
      next: (
        (data) => {
          this.wbsCashFlowForm.controls['totalReconcillation']?.setValue(data.recon.total_usd, { emitEvent: false });
          this.wbsCashFlowForm.controls['totalInvoicedPo']?.setValue(data.po.amount_in_local_currency, { emitEvent: false });
          this.wbsCashFlowForm.controls['totalAmountJvs']?.setValue(data.jv.amount_in_doc_currency, { emitEvent: false });
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })

    });
  }
      if (this.capexFormData.wbsDetailsForm.value.adjustedWbsAmount) {
        this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(this.capexFormData.wbsDetailsForm.value.adjustedWbsAmount, { emitEvent: false });
      } else {
        this.wbsCashFlowForm.controls['currentWbsAmount']?.setValue(Math.round(totalCost).toFixed(2), { emitEvent: false });
      }
    }

    setFormValue(capexAllFields:any){
      console.log("wbs Draft data",capexAllFields);
  
      this.wbsCashFlowForm.setValue({
      estimatedTotalCost: capexAllFields?.Estimated_Total_Cost || '',
      currentWbsAmount: capexAllFields?.Current_Wbs_Amount || '',
      totalReconcillation: capexAllFields?.Total_Reconcillation || '',
      approvedPoCommits: capexAllFields?.Approved_Po_Commits || '', 
      totalInvoicedPo: capexAllFields?.Total_Invoiced_Po || '',
      totalAmountJvs: capexAllFields?.Total_Amount_Jvs || '',
      TotalCashFlow: capexAllFields?.Total_Cash_Flow || '',
      estimatedTotalSpend: capexAllFields?.Estimated_Total_Spend || '',
      estimatedRemainingSpend: capexAllFields?.Estimated_Remaining_Spend || '',
      monthsStatic: capexAllFields?.Months_Static || '',
      engrNreCost: capexAllFields?.Engr_Nre_Cost || '',
      techNreCost: capexAllFields?.Tech_Nre_Cost || '',
      totalNreCost: capexAllFields?.Total_Nre_Cost || '',
      engrNreBilled: capexAllFields?.Engr_Nre_Billed || '',
      techNreBilled: capexAllFields?.Tech_Nre_Billed || '',
      totalNreBilled: capexAllFields?.Total_Nre_Billed || '',
      remainingNre: capexAllFields?.Remaining_Nre || '',
      hookupCapitalized: capexAllFields?.Hookup_Capitalized || '',
      hookupBiiled: capexAllFields?.Hookup_Biiled || '',
      remainingHookup: capexAllFields?.Remaining_Hookup || '',
      })
      console.log("Form values after setting", this.wbsCashFlowForm.value);
    }

    getFormValue() {
      return this.wbsCashFlowForm.getRawValue();
    }



}