<form [formGroup]="wbsCashFlowDetailsForm" class="cash-flow-form">

    <div class="row border-container">
        <span class="text-over-border">Purchase Orders</span>
    </div>


    <!-- Purchase Orders Section -->
    <div class="section">
        <!-- <table class="details-table">
            <thead>
                <tr>
                    <th>PO Num</th>
                    <th>Req Num</th>
                    <th>PO Date</th>
                    <th>Vendor</th>
                    <th>PO Amount ($)</th>
                    <th>Invoiced Amount ($)</th>
                    <th *ngFor="let header of earlyEntryHeaders">{{ header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let po of purchaseOrders; let i = index" [formGroupName]="i">
                    <td>{{ po.poNum }}</td>
                    <td>{{ po.reqNum }}</td>
                    <td>{{ po.poDate }}</td>
                    <td>{{ po.vendor }}</td>
                    <td>{{ po.poAmount }}</td>
                    <td>{{ po.invoicedAmount }}</td>
                    <td *ngFor="let header of earlyEntryHeaders">
                        <input type="text" formControlName="{{ header }}" />
                    </td>
                </tr>
            </tbody>
        </table> -->
    </div>

    <div class="row border-container">
        <span class="text-over-border">Journal Vouchers</span>
    </div>

    <!-- Journal Vouchers Section -->
    <div class="section">
        <!-- <table class="details-table">
            <thead>
                <tr>
                    <th>Rec Type</th>
                    <th>Posting Date</th>
                    <th>Doc Num</th>
                    <th>Doc Item</th>
                    <th>SIC Doc Num</th>
                    <th>Description</th>
                    <th>Total ($)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jv of journalVouchers; let i = index" [formGroupName]="i">
                    <td>{{ jv.recType }}</td>
                    <td>{{ jv.postingDate }}</td>
                    <td>{{ jv.docNum }}</td>
                    <td>{{ jv.docItem }}</td>
                    <td>{{ jv.sicDocNum }}</td>
                    <td>{{ jv.description }}</td>
                    <td>{{ jv.total }}</td>
                </tr>
            </tbody>
        </table> -->
    </div>

    <div class="row border-container">
        <span class="text-over-border">Invoices</span>
    </div>


    <!-- Invoices Section -->
    <div class="section">
        <!-- <table class="details-table">
            <thead>
                <tr>
                    <th>Rec Type</th>
                    <th>Posting Date</th>
                    <th>Doc Num</th>
                    <th>Doc Item</th>
                    <th>Invoice Num</th>
                    <th>SIC Doc Num</th>
                    <th>PO Num</th>
                    <th>Vendor</th>
                    <th>Description</th>
                    <th>Total ($)</th>
                    <th>Service Type</th>
                    <th *ngFor="let header of earlyEntryHeaders">{{ header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let invoice of invoices; let i = index" [formGroupName]="i">
                    <td>{{ invoice.recType }}</td>
                    <td>{{ invoice.postingDate }}</td>
                    <td>{{ invoice.docNum }}</td>
                    <td>{{ invoice.docItem }}</td>
                    <td>{{ invoice.invoiceNum }}</td>
                    <td>{{ invoice.sicDocNum }}</td>
                    <td>{{ invoice.poNum }}</td>
                    <td>{{ invoice.vendor }}</td>
                    <td>{{ invoice.description }}</td>
                    <td>{{ invoice.total }}</td>
                    <td>{{ invoice.serviceType }}</td>
                    <td *ngFor="let header of earlyEntryHeaders">
                        <input type="text" formControlName="{{ header }}" />
                    </td>
                </tr>
            </tbody>
        </table> -->
    </div>
</form>