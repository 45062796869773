import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})

export class AssetDetailsComponent {
  assetDetailsForm!: FormGroup;
  numberOfAssets!: FormArray;
  subscriptions: Subscription[] = [];
  buildings:any;
  deprManagerSelected:any;
  displayedColumns: string[] = ['deprCostCenter', 'deprCostObjManager', 'assetClass2', 'assetClassDescription', 'building', 'labfabInstallLocation', 'toolOwner', 'assetUsefulLife2', 'assetDescription' , 'assetValue' , 'assetNumber', 'assetComments'];
  tableDetailsArray: any = [];
  dataSource: any
  financialCostCenterData: any;
  assetClassDescOptionsRow: string [] [] = [];
  filteredLabFabNameRow: string [] [] = [];
  deprCostCenOpt: any;
  assetOutput :AssetDetails[]= [];
  ELEMENT_DATA: AssetDetails[] = [];
  wbs_number: any;
  estimatedProjectEndDate: any;
  totalCost: any;
  totalCashFlow: any;
  totalSpendFromReconciliationReport: any;

  initialDisabledStates: { [key: string]: boolean } = {
    totalCashFlow: true,
    report: true,
    // Assuming false for all other fields as they are not disabled initially
    assets: false,
    assetUsefulLife: false,
    assetClass: false,
    assetDesc: false,
  };

  minDate = new Date();
  formattedDateCommit: string = '';
  formattedStartDate: string = '';
  numberOfAsset: any;
  assetClassOptions: string[] = [] ;  
  assetClassSelected='1510';
  assetClassDesSelected: string = "";
  assetClassDescOptions = ['Air Compressor', 'Bench, Work, Lab', 'Lathe' , 'Cabinet, Storage' , 'Lift Table, Scissors' , 'Car Wash' , 'Milling Machine' , 'Cold Pan' , 'Mounting Pad' , 'Condiment Stand Life of the related asset' , 'Conveyer System (single-department)' , 'Paint Spray Booth' , 'Degreaser' , 'Scissor Lift & Hoist' , 'Welder' , 'Auto Insertion Machine' , 'Grinder' , 'Cash Register' , 'Microscope' , 'Contour Projector' , 'Refrigerator or Freezer' , 'Fork Lift Truck' , 'Test Chamber' , 'Gauge' , 'Tumbler']
  filteredBuilding: any[] = [];
  siteName: any;
  filteredLabFabName: any[] = [];
  @Output() assetFormDataChange = new EventEmitter<any>();
  requestDetailsFormData: any;
  financialDetailsFormData: any;
  deprCostCSelected:any;
  buildingSelected:string = '';
  labFabSelected: string = '';
  toolOwnerSelected: string = '';
  assetClassData:any;
  assetUsefulLifeSelected: string = '';
  warningShown: boolean = false;
  capexFormData: any;
  isAnalystButtonVisible = true;
  isMakeAssetButtonVisible = true;
  ltpNum: string ='';
  setupActions = ['Asset Setup','Cancel'];
  isSubmittedToAnalyst: boolean = false;
  assets: number = 0;

  userRole = this.common.getUserRole();
  isRequester: boolean = this.userRole === 'Requester'? true : false;
  isPlanner: boolean = this.userRole === 'Planner'? true : false;

  @Output() porStatusUpdated = new EventEmitter<string>();
  @Output() assetusefullife = new EventEmitter<string>();
  
  constructor(private common: CommonService, private fb: FormBuilder, 
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {  
    }

    ngOnInit(){
      this.assetDetailsForm = this.fb.group({

        //Asset Setup
        assets: [''],
        assetUsefulLife: [''],
        // assetClass: [{value: '1510', disabled: true}],
        assetClass: ['1510'],
        assetDesc: [''],
        totalCashFlow: [{value: '', disabled: true}],
        report: [{value: '', disabled: true}],
    
        //Asset Closure
        actualSetupDate: [''],
        actualSetupMatDate: [''],
        setupComment: [''],
        setupAction: [''],
    
        numberOfAssets: this.fb.array([])
    
      });

      this.numberOfAssets = this.assetDetailsForm.get('numberOfAssets') as FormArray;

      this.common.getCapexAPI('ltp-por/get-asset-class').subscribe({
        next: (
          (data) => {
            this.assetClassData = data.data;
            this.assetClassData.sort((a: { Asset_Class: number }, b: { Asset_Class: number }) => a.Asset_Class - b.Asset_Class);
            this.assetClassOptions = this.assetClassData.map((item: { Asset_Class: any; })=>item.Asset_Class);
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
          })
      });
  

      this.subscriptions.push(
        this.common.buildingData.subscribe(
          {
            next: (
              (res) => {
                this.filteredBuilding = res.filteredBuilding;
                this.buildings = res.buildings;
                // console.log("building dataAsset", this.buildings);
              }
            ),
            error: (
              error => {
                this.common.errorHandler(error);
              }
            )
          }
        )
      )

      this.common.getCapexAPI('capex-get-costcenter-data-itg').subscribe({
        next: (
          (data) => {
            this.financialCostCenterData = data['CostCenters']
            // console.log("Cost Center Details", this.financialCostCenterData);
            this.deprCostCenOpt = this.financialCostCenterData?.filter((e: any) => e['Depreciation'] === "True" || e['Depreciation'] === true).map((a: any) => a['LAB_ID'])
            // this.depricCostCSelected = data.controls.financialDetails.controls.deprCCManager.value
            // deprCostCSelected = this.financialDetailsFormData.depreciateCostCenter;
          }),
        error: (
          (err) => {
            console.error("Error occurred:", err);
          })
      });

      // this.subscriptions.push(
      //   this.common.estimatedEndDate.subscribe(
      //     {
      //       next: (
      //         (res) => {
      //           this.assetDetailsForm.controls['actualSetupDate']?.setValue(res.estimatedDate);
      //           this.assetDetailsForm.controls['actualSetupMatDate']?.setValue(res.estimatedDate, { emitEvent: false });

      //         }
      //       ),
      //       error: (
      //         error => {
      //           this.common.errorHandler(error);
      //         }
      //       )
      //     }
      //   )
      // )
      if(this.isRequester){
        this.assetDetailsForm.disable();
      }


    }

    onValueChange(event: any, fieldName: string) {
      console.log(event);
      if(fieldName == 'assetUsefulLife'){
      const inputAssetUsefulLife = (event.target as HTMLInputElement).value;
      this.assetusefullife.emit(inputAssetUsefulLife);
      }
    }
  
    quarterCalculation(selectedDate: any){
      let year = selectedDate.getFullYear().toString().substr(-2);
        let quarter = 'Q1';
        let fullYear = selectedDate.getFullYear();
        let nextQuarterMonth = 0;
        if ((selectedDate.getMonth() + 1) < 2) {
          quarter = 'Q1';
          nextQuarterMonth = 2;
          if ((selectedDate.getMonth() + 1) === 0) {
            year = (selectedDate.getFullYear() - 1).toString().substr(-2);
          }
        } else if ((selectedDate.getMonth() + 1) < 5) {
          quarter = 'Q2';
          nextQuarterMonth = 5;
        } else if ((selectedDate.getMonth() + 1) < 8) {
          quarter = 'Q3';
          nextQuarterMonth = 8;
        } else if ((selectedDate.getMonth() + 1) < 11) {
          quarter = 'Q4';
          nextQuarterMonth = 11;
        } else {
          quarter = 'Q1';
          year = (selectedDate.getFullYear() + 1).toString().substr(-2);
        }
        console.log("Year", year);
        return { year, quarter, fullYear, nextQuarterMonth};
    }
  
    onDateChange(event: MatDatepickerInputEvent<Date>, fieldName: string, picker: MatDatepicker<Date>) {
      let selectedDate = event.value;
      let selectedField = fieldName;
      const formattedSelectedDate = selectedDate ? `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate() ?? ''}` : '';
  
      if (selectedDate) {
        switch (selectedField) {
          case 'actualSetupDate':
            let yearDep = selectedDate.getFullYear();
            let resultMonthDep = (selectedDate.getMonth()+1).toString(); 
            let resultDateDep = selectedDate.getDate().toString(); 
            if(selectedDate.getDate()<10){
              resultDateDep = "0"+ resultDateDep;          
            }
            if((selectedDate.getMonth()+1)<10){
              resultMonthDep = "0"+ resultMonthDep          
            }
            let resultDep = `${resultMonthDep}-${resultDateDep}-${yearDep}`;
            
            //show formatted quarter Date - FY24 Q1
            this.assetDetailsForm.controls['actualSetupDate']?.setValue(resultDep, { emitEvent: false });
            this.assetDetailsForm.controls['actualSetupMatDate']?.setValue(selectedDate, { emitEvent: false });
            let formattedSelectedDate = `${yearDep}-${resultMonthDep}-${resultDateDep}`;
            this.assetDetailsForm.controls['actualSetupDateDB']?.setValue(formattedSelectedDate, { emitEvent: false });
            this.getEstimatedProjectEnd(selectedDate);
            picker.close();
            break;  
        }
      this.assetFormDataChange.emit(this.assetDetailsForm);
      }
    }
  
    getEstimatedProjectEnd(selectedDate:Date){
      let estimatedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 6, selectedDate.getDate());
      let estimatedYear = estimatedDate.getFullYear().toString().substr(-2);
      let estimatedQuarter = 'Q1';
  
      if ((estimatedDate.getMonth() + 1) < 2) {
        estimatedQuarter = 'Q1';
        if ((estimatedDate.getMonth() + 1) === 0) {
          estimatedYear = (estimatedDate.getFullYear() - 1).toString().substr(-2);
        }
      } else if ((estimatedDate.getMonth() + 1) < 5) {
        estimatedQuarter = 'Q2';
      } else if ((estimatedDate.getMonth() + 1) < 8) {
        estimatedQuarter = 'Q3';
      } else if ((estimatedDate.getMonth() + 1) < 11) {
        estimatedQuarter = 'Q4';
      } else {
        estimatedQuarter = 'Q1';
        estimatedYear = (estimatedDate.getFullYear() + 1).toString().substr(-2);
      }
      this.assetDetailsForm.controls['estimatedProjectEnd']?.setValue(`FY${estimatedYear} ${estimatedQuarter}`);
    }
  
    dropdownClick(event:any, fieldName:string){
  
    }

    onRequestDetailsFormValid(data: any){
      if(data != 'Warning message'){
        // Object.keys(this.assetDetailsForm.controls).forEach(key => {
        //   const control = this.assetDetailsForm.get(key);
        //   if (control && !this.initialDisabledStates[key]) {
        //       control.enable();
        //   }
        // });

        // Object.keys(this.assetDetailsForm.controls).forEach(key => {
        //   const myControl = this.assetDetailsForm.get(key);
        //   if(myControl && myControl.disabled && (myControl.validator || myControl.enabled)){
        //     this.assetDetailsForm.get(key)?.enable();
        //   }
        // });
        this.requestDetailsFormData = data.controls.requestDetails.value;
        this.buildingSelected = this.requestDetailsFormData.building;
        this.filteredLabFabName = this.buildings?.filter((e: any) => e.Lab_name == this.buildingSelected).map((a: any) => a.LabFab_Name)
        this.filteredLabFabName = this.filteredLabFabName?.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
          })
          this.filteredLabFabName?.sort();
        this.financialDetailsFormData = data.controls.financialDetails.value;
        this.assetDetailsForm.controls['assetUsefulLife'].setValue(this.requestDetailsFormData.assetUsefulLife);
        this.assets = this.requestDetailsFormData.assets;
        this.assetDetailsForm.controls['assets'].setValue(this.requestDetailsFormData.assets);
        this.numberOfAsset = Array(Number(this.requestDetailsFormData.assets));
        const assetsDetails: AssetDetails[] = [];
        this.deprCostCSelected = this.financialDetailsFormData.depreciateCostCenter;
        this.siteName = this.requestDetailsFormData.buildSite;
        this.labFabSelected = this.requestDetailsFormData.labfabInstallLocation;
        this.deprManagerSelected = data.controls.financialDetails.controls.deprCCManager.value;
        // this.toolOwnerSelected = this.buildings.filter((item: { LabFab_Name: string; })=>item.LabFab_Name==this.labFabSelected).map((x: { Lab_Owner: any; })=>x.Lab_Owner)[0];
        this.toolOwnerSelected = (this.buildings && Array.isArray(this.buildings)) ? this.buildings.filter((item: { LabFab_Name: string; }) => item.LabFab_Name === this.labFabSelected).map((x: { Lab_Owner: any; }) => x.Lab_Owner)[0] || null: null;
        this.assetUsefulLifeSelected = this.requestDetailsFormData.assetUsefulLife;
        for (let i = 0; i < this.numberOfAsset.length ; i++) {
          // Create an AssetDetails object for each asset
          const assetDetail: AssetDetails = {
            deprCostCenter: this.financialDetailsFormData.depreciateCostCenter,
            deprCostObjManager:this.deprManagerSelected ,
            assetClass2: this.assetClassSelected,
            assetClassDescription: this.requestDetailsFormData.itemDescription,
            building: this.requestDetailsFormData.building,
            labfabInstallLocation: this.requestDetailsFormData.labfabInstallLocation,
            toolOwner: this.toolOwnerSelected,
            assetUsefulLife2: this.requestDetailsFormData.assetUsefulLife,
            assetDescription:'',
            assetValue: '',
            assetNumber: '',
            assetComments: ''
          };

          // Add the object to the array
          assetsDetails.push(assetDetail);
        }
        console.log("asset details", assetsDetails);
        //this.numberOfAssets.clear(); // Clear existing entries
        assetsDetails.forEach(detail => {
          this.numberOfAssets.push(this.fb.group({
            deprCostCenter: [detail.deprCostCenter],
            deprCostObjManager: [detail.deprCostObjManager],
            assetClass2: [detail.assetClass2],
            assetClassDescription: [detail.assetClassDescription],
            building: [detail.building],
            labfabInstallLocation: [detail.labfabInstallLocation],
            toolOwner: [detail.toolOwner],
            assetUsefulLife2: [detail.assetUsefulLife2],
            assetDescription: [detail.assetDescription],
            assetValue: [detail.assetValue],
            assetNumber: [detail.assetNumber],
            assetComments: [detail.assetComments]
          }));
        });

      this.ELEMENT_DATA = assetsDetails;
      this.assetOutput = assetsDetails;
    //  this.addAsset(this.numberOfAsset.length);
      this.dataSource = new MatTableDataSource<AssetDetails>(this.numberOfAssets.value);
      //this.addAsset(this.numberOfAsset.length);
      //this.dataSource = new MatTableDataSource<AssetDetails>(this.ELEMENT_DATA);
      // this.addAsset(this.numberOfAsset.length);
      // this.dataSource = new MatTableDataSource<AssetDetails>(this.ELEMENT_DATA);
      // this.filteredDataSource = this.dataSource.filteredData;
      // console.log(this.numberOfAssets.controls);        
      this.setupRealTimeValidation();
      }
      else{
        this.assetDetailsForm.reset();
        this.common.show('warning', 'Please fill all the required fields in WBS Details Section before proceeding to Asset Setup Details');
        this.assetDetailsForm.disable();
      }
    }

    onManagerChange(selectedValue: string, index: number): void {
      const assetFormGroup = this.numberOfAssets.at(index) as FormGroup;
      this.deprCostCSelected = selectedValue;
      const entry = this.financialCostCenterData.filter((e: any) => e['LAB_ID'] == selectedValue);
      this.deprManagerSelected = entry[0]['Cost Center Owner'],
      assetFormGroup.get('deprCostObjManager')?.setValue(this.deprManagerSelected || '');

      // this.financialDetailsForm.controls['depreciateCostCenter'].valueChanges
      // .subscribe((value) => {
      //   Object.keys(this.financialDetailsForm.controls).forEach(key => {
      //     if (key !== 'depreciateCostCenter' && key.includes('depr')) {
      //       this.financialDetailsForm.controls[key].reset();
      //     }
      //   })
      //   this.selectedCCRow = this.financialCostCenterData.filter((e: any) => e['Cost Center'] == this.financialDetailsForm.controls['depreciateCostCenter'].value);
      //   if (this.selectedCCRow.length > 0) {
      //     this.financialDetailsForm.patchValue({
      //       'deprCCManager': this.selectedCCRow[0]['Cost Center Manager'],
    }
    
    onAssetClassChange(selectedValue: string): void {
      this.assetClassSelected = selectedValue;
      this.assetClassDescOptions = this.assetClassData.filter((item: { Asset_Class: any; })=>item.Asset_Class==selectedValue)[0].Data.map((x: { Asset_Class_Description: any; })=>x.Asset_Class_Description);
      const assetArray = this.assetDetailsForm.get('numberOfAssets') as FormArray;
      assetArray.controls.forEach(control => {
      (control.get('assetClass2') as FormControl).setValue(selectedValue, { emitEvent: false });
      for(let i=0; i<this.numberOfAsset.length;i++){
        this.assetClassDescOptionsRow[i] = this.assetClassDescOptions;
      }

    });
    }

    onAssetClassDesChange(selectedValue:string): void{
      this.assetClassDesSelected = selectedValue;
      const assetArray = this.assetDetailsForm.get('numberOfAssets') as FormArray;
      assetArray.controls.forEach(control => {
      (control.get('assetClassDescription') as FormControl).setValue(selectedValue, { emitEvent: false });
      });
    }

    SearchLabFabName(selectedValue : string, index: number): void {
      this.buildingSelected = selectedValue;
      let updatedLabFab = this.buildings?.filter((e: any) => e.Lab_name == this.buildingSelected).map((a: any) => a.LabFab_Name)
      updatedLabFab = updatedLabFab?.filter(function(elem: any, index: any, self: string | any[]) {
            return index === self.indexOf(elem); 
          })
          updatedLabFab?.sort();
      this.filteredLabFabNameRow[index] = updatedLabFab;
    }

    SearchtoolOwner(selectedValue: string, index:number): void{
      const assetFormGroup = this.numberOfAssets.at(index) as FormGroup;
      this.toolOwnerSelected = this.buildings.filter((item: { LabFab_Name: string; })=>item.LabFab_Name==selectedValue).map((x: { Lab_Owner: any; })=>x.Lab_Owner)[0];
      assetFormGroup.get('toolOwner')?.setValue(this.toolOwnerSelected || '');

    }

    createAssetFormGroup(): FormGroup {
      return this.fb.group({
        deprCostCenter: [this.deprCostCSelected],
        deprCostObjManager: [{value: this.deprManagerSelected, disabled: true}],
        assetClass2: [this.assetClassSelected],
        assetClassDescription: [this.assetClassDesSelected],
        building: [this.buildingSelected],
        labfabInstallLocation: [this.labFabSelected],
        toolOwner: [{value: this.toolOwnerSelected, disabled: true}],
        //assetUsefulLife2: [this.assetUsefulLifeSelected, [Validators.min(1), Validators.max(40)]],
        assetUsefulLife2: ['',[Validators.min(1), Validators.max(40)]],
        assetDescription: [''],
        assetValue:  [{value:'', validators: [Validators.min(5000), this.fourDigitValidator]}],
        assetNumber: ['', [Validators.min(100000000000), Validators.max(999999999999)]],
        assetComments: ['']
      });
    }

    fourDigitValidator(control: AbstractControl): { [key: string]: boolean } | null {
      const value = control.value;
      let errors: { [key: string]: boolean } = {}; // Explicitly define the type of errors object
      if (value && value.toString().length < 4) {
        errors['fourDigit'] = true;
      }
  
      if (value && value < 5000) {
        errors['min'] = true;
      }
      return Object.keys(errors).length ? errors : null;
    }

    // get numberOfAssets(): FormArray {
    //   return this.assetDetailsForm.get('numberOfAssets') as FormArray;
    // }


    // addAsset(n:number):void {
    //   this.numberOfAssets.clear(); 
    //   for (let i=0;i<n;i++){
    //     this.numberOfAssets.push(this.createAssetFormGroup());
    //   }
    // }

    addAsset(n: number): void {
      const existingAssets = this.numberOfAssets.controls.length;
    
      // If the number of assets is equal to n, do nothing
      if (existingAssets === n) {
        return;
      }
    
      // Clear extra rows if existingAssets are greater than n
      if (existingAssets > n) {
        for (let i = existingAssets - 1; i >= n; i--) {
          this.numberOfAssets.removeAt(i);
        }
      }
      
      else {
        // If existingAssets are less than n, add more rows
        for (let i = existingAssets; i < n; i++) {
          this.numberOfAssets.push(this.createAssetFormGroup());
        }
      }
    }


    assetClassRowChange(selectedValue: string, index: number): void{
      const assetDescChange = this.assetClassData?.filter((item: { Asset_Class: any; })=>item.Asset_Class==selectedValue)[0].Data.map((x: { Asset_Class_Description: any; })=>x.Asset_Class_Description);
      this.assetClassDescOptionsRow[index] = assetDescChange;
    }

    setupRealTimeValidation(): void {
      // Assuming 'assetUsefulLife' is in the 'numberOfAssets' FormArray
      this.numberOfAssets.controls.forEach((control: AbstractControl, index: number) => {
        const assetUsefulLifeControl = control.get('assetUsefulLife2');
        if (assetUsefulLifeControl) {
          this.subscriptions.push(
            assetUsefulLifeControl.valueChanges.subscribe(value => {
              if (assetUsefulLifeControl.hasError('min')) {
                let toast = this.toastr.warning(
                  'Asset Useful Life must not be less than 1.',
                  '',
                  { onActivateTick: true, timeOut: 5000 }
                );
              } 
              if (assetUsefulLifeControl.hasError('max')) {
                let toast = this.toastr.warning(
                  'Asset Useful Life must be no more than 40.',
                  '',
                  { onActivateTick: true, timeOut: 5000 }
                );
              }
            })
          );
        }
      });
    }

    
    validateAssetNumber() {
      this.numberOfAssets.controls.forEach((control: AbstractControl, index: number) => {
        const assetNumberControl = control.get('assetNumber');
        if (assetNumberControl) {
          this.subscriptions.push(
            assetNumberControl.valueChanges.subscribe(value => {
              if (assetNumberControl.hasError('min')) {
                this.toastr.warning(
                  'Asset Number must be exactly 12 characters long.',
                  '',
                  { onActivateTick: true, timeOut: 2000 }
                );
              }
              if (assetNumberControl.hasError('max')) {
                this.toastr.warning(
                  'Asset Number must be exactly 12 characters long.',
                  '',
                  { onActivateTick: true, timeOut: 2000 }
                );
              }
            })
          );
        }
      });
    }
    
    showAlert(message: string): void {
      alert(message);
    }
    
    validateItemCostControl(row: any) {
      const assetValueControl = row.get('assetValue');
      const value = assetValueControl.value;

       // Format to two decimal places
       const formattedValue = parseFloat(value).toFixed(2);
       if (assetValueControl.hasError('min')) {
        let toast = this.toastr.warning(
          'Asset value has been set to less than $5000. Please ensure that each asset has a value of $5000 or greater.',
          '',
          { onActivateTick: true, timeOut: 5000 }
        );
      } else{
       assetValueControl.setValue(formattedValue);
      }

    }
    

    // validateItemCostControl() {
    //   // Existing logic (example code, adjust based on your actual logic)
    //   this.numberOfAssets.controls.forEach((control: AbstractControl, index: number) => {
    //     const assetValueControl = control.get('assetValue');
    
    //     if (assetValueControl) {
    //       const currentValue = assetValueControl.value;
    
    //       // Add decimal formatting logic
    //       if (!isNaN(currentValue)) {
    //         const formattedValue = parseFloat(currentValue).toFixed(2);
    
    //         // Update the FormControl with the formatted value
    //         assetValueControl.setValue(+formattedValue, { emitEvent: false });
    //       }
    
    //       // Example: Additional validation or logic
    //       if (currentValue < 5000) {
    //         assetValueControl.setErrors({ min: true });
    //       } else {
    //         assetValueControl.setErrors(null);
    //       }
    //     }
    
    //     // Other existing logic (if any)
    //   });
    // }
    

    onSubmit(): void {
      const formValue = this.assetDetailsForm.value;

      const numberOfAssetsArray = this.assetDetailsForm.get('numberOfAssets') as FormArray;
  
      // Manually include the disabled control value      
    
      const deprCostObjManagerValue = numberOfAssetsArray.controls.map((control: AbstractControl) => {
        const group = control as FormGroup;
        return group.get('deprCostObjManager')?.value;
      });

      const toolOwnerName = numberOfAssetsArray.controls.map((control: AbstractControl) => {
        const group = control as FormGroup;
        return group.get('toolOwner')?.value;
      });

  
      // Combine the form values with manually included disabled control values
      const finalSubmissionValue = {
        ...formValue,
        numberOfAssets: formValue.numberOfAssets.map((row: any, index: number) => ({
          ...row,
          toolOwnerName: toolOwnerName[index],
          deprCostObjManagerValue: deprCostObjManagerValue[index]
        }))
      };
  
      // Log or handle the final submission value
      if(this.assetDetailsForm.valid){
        console.log("Final Submission Value:", finalSubmissionValue);
      }
    }

    getFormValue() {
      return this.assetDetailsForm.getRawValue();
    }

    setFormValue(formData: any){
      this.isSubmittedToAnalyst = formData?.Is_Submitted_For_Analyst_Review || false;

      console.log("Asset Draft Data", formData);

      this.assetDetailsForm.patchValue({
        assets: formData.Number_of_Assets,
        assetUsefulLife: formData.Asset_Setup_Useful_Life,
        assetClass: formData.Asset_Class || '1510', // default value if undefined
        assetDesc: formData.Asset_Class_Description,
        totalCashFlow: formData.Total_Cash_Flow,
        report: formData.Recon_Total,
        actualSetupDate: formData.Actual_Asset_Setup_Date,
        actualSetupMatDate: formData.Actual_Asset_Setup_Date_ISO,
        setupComment: formData.Asset_Setup_Comment,
        setupAction: formData.Asset_Setup_Action,
        // Ensure that the numberOfAssets FormArray is cleared before adding new controls
        numberOfAssets: []
      });

     // this.numberOfAssets.clear();

    //  if(formData.Assets_Data.length !== formData.Number_of_Assets){

    //   for (let i = formData.Number_of_Assets - 1; i >= formData.Assets_Data.length; i--) {
    //     //this.numberOfAssets.removeAt(i);
    //     formData.Assets_Data.splice(i, 1); 
    //   }

    if(formData.Assets_Data.length > Number(formData.Number_of_Assets)){
      if (formData.Assets_Data && Array.isArray(formData.Assets_Data)) {
        const visibleAssets = formData.Assets_Data.slice(0, formData.Number_of_Assets);
        visibleAssets.forEach((asset: AssetDetails, index: number) => {
          this.numberOfAssets.push(this.fb.group({
            deprCostCenter: [asset.deprCostCenter],
            deprCostObjManager: [{ value: asset.deprCostObjManager, disabled: true }],
            assetClass2: [asset.assetClass2],
            assetClassDescription: [asset.assetClassDescription],
            building: [asset.building],
            labfabInstallLocation: [asset.labfabInstallLocation],
            toolOwner: [{ value: asset.toolOwner, disabled: true }],
            assetUsefulLife2: [asset.assetUsefulLife2],
            assetDescription: [asset.assetDescription],
            assetValue: [asset.assetValue],
            assetNumber: [asset.assetNumber],
            assetComments: [asset.assetComments]
          }));

          this.assetClassRowChange(asset.assetClass2, index);
          this.SearchLabFabName(asset.building, index);
        });
      }
    }

      else if(formData.Assets_Data.length <= Number(formData.Number_of_Assets)){

        if (formData.Assets_Data && Array.isArray(formData.Assets_Data)) {
          formData.Assets_Data.forEach((asset: AssetDetails, index: number) => {
            this.numberOfAssets.push(this.fb.group({
              deprCostCenter: [asset.deprCostCenter],
              deprCostObjManager: [{ value: asset.deprCostObjManager, disabled: true }],
              assetClass2: [asset.assetClass2],
              assetClassDescription: [asset.assetClassDescription],
              building: [asset.building],
              labfabInstallLocation: [asset.labfabInstallLocation],
              toolOwner: [{ value: asset.toolOwner, disabled: true }],
              assetUsefulLife2: [asset.assetUsefulLife2],
              assetDescription: [asset.assetDescription],
              assetValue: [asset.assetValue],
              assetNumber: [asset.assetNumber],
              assetComments: [asset.assetComments]
            }));
  
            this.assetClassRowChange(asset.assetClass2, index);
            this.SearchLabFabName(asset.building, index);
          });
        }
      }
    


      // this.ELEMENT_DATA = formData.numberOfAssets || [];
      // this.assetOutput = formData.numberOfAssets || [];
      // this.dataSource = new MatTableDataSource<AssetDetails>(this.ELEMENT_DATA);
        this.addAsset(Number(formData.Number_of_Assets));
    }

    accessAssetDetails(data: any){
      this.capexFormData = data; 
      this.wbs_number = this.capexFormData.wbsDetailsForm.value.wbs;
      this.estimatedProjectEndDate = this.capexFormData.estimatedProjectEndDate;
      this.totalCost = this.capexFormData.totalCost;
      this.totalCashFlow = this.capexFormData.wbsCashFlowForm.value.TotalCashFlow;
      this.totalSpendFromReconciliationReport = this.capexFormData.wbsCashFlowForm.value.totalReconcillation;
      this.setTotalCashFlow();
      if(this.isSubmittedToAnalyst == true){
        this.isAnalystButtonVisible = false;
      }
      if( this.capexFormData.PORStatus === 'Active - Asset Setup' || this.capexFormData.PORStatus === 'Complete'){
        this.isAnalystButtonVisible = false;
        this.isMakeAssetButtonVisible = false;
      }
      this.assetDetailsForm.controls['assets'].disable();
      console.log('CapexData: ' , data);
    }

    setTotalCashFlow() {
      this.assetDetailsForm.get('totalCashFlow')?.setValue(this.capexFormData.wbsCashFlowForm.value.TotalCashFlow);
      this.assetDetailsForm.get('report')?.setValue(this.capexFormData.wbsCashFlowForm.value.totalReconcillation);
    }

    SubmitForAnalystReview() {
      this.common.loader.next(true);
      this.ltpNum = this.capexFormData.ltpNumber;
      this.isSubmittedToAnalyst = true;
      this.assetDetailsForm.get('assetClass')?.enable();
      this.assetDetailsForm.get('totalCashFlow')?.enable();
      this.assetDetailsForm.get('report')?.enable();
      const payload = {
        ltpNumber: this.ltpNum,
        version: this.capexFormData.version,
        por: this.capexFormData.POR,
        porStatus: this.capexFormData.PORStatus,
        isSubmittedToAnalyst: this.isSubmittedToAnalyst,
        assetDetails: {
       // ...this.assetDetailsForm.value
       ...this.assetDetailsForm.getRawValue()
        }
      };
        console.log(payload);
        this.common.postCapexAPI('ltp-por/save/asset-setup', payload).subscribe({
          next: (data) => {
            //this.assetDetailsForm.get('assetClass')?.disable();
            this.assetDetailsForm.get('totalCashFlow')?.disable();
            this.assetDetailsForm.get('report')?.disable();
            this.common.loader.next(false);
            this.common.show('success', 'Asset Details submitted for analyst review');
            this.isAnalystButtonVisible = false;
            console.log(data);
            
            if(this.isPlanner){
              this.assetDetailsForm.disable(); 
              this.isAnalystButtonVisible = false;            
            }

            const estimatedProjectEndQtr = this.common.getQuarterFromDate(this.estimatedProjectEndDate);

            // if(this.userRole === 'Admin'){
            //   this.isAnalystButtonVisible = false; 
            // }
            const notificationBody = {
              notificationType: 'Ready For Asset Setup',
              ltp: this.capexFormData.ltpNumber,
              por: this.capexFormData.POR,
              wbs: this.wbs_number,
             // planner: this.capexFormData.requestDetails.planner || '',
              projectManager: this.capexFormData.requestDetails.projectManagerEmail || '',
              toolDescription: this.capexFormData.requestDetails.itemDescription || '',
              commitQuarter: this.capexFormData.cashFlowDetails.eventQuarter.commitQuarter || '',
             // building: this.capexFormData.requestDetails.building || '',
              labFabInstallLocation: this.capexFormData.requestDetails.labfabInstallLocation || '',
              //commitManager: this.capexFormData.financialDetails.commitCCManager || '',
             // depreciationManager: this.capexFormData.depreciationManager ? `${this.capexFormData.depreciationManager}@hp.com` : '',
              // depreciationStartDate: this.capexFormData.wbsDetailsForm.value.depreciationStartDate || '',
              // estimatedDepreciationCost: this.capexFormData.estDepCost || '',
              assets: this.capexFormData.requestDetails.assets || '',
              assetLife: this.capexFormData.requestDetails.assetUsefulLife || '',
              // depreciationSubMRU: this.capexFormData.DepricationSubMRU || '',
              // commitSubMRU: this.capexFormData.CommitSubMRU || ''
              estimatedProjectEnd: estimatedProjectEndQtr,
              totalCost: this.totalCost || '',
              totalCashFlow:this.totalCashFlow || 0,
              totalSpendFromReconciliationReport: this.totalSpendFromReconciliationReport || '',

            };
    
            console.log(notificationBody);
            this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
              next: (notificationData) => {
                console.log(notificationData);
                this.common.show('success', 'Email notification sent successfully.');
                this.common.loader.next(false);
              },
              error: (notificationErr) => {
                console.error("Error sending email notification:", notificationErr);
                this.common.show('error', 'Failed to send email notification.');
              }
            });
         },
          error: (err) => {
            console.error("Error occurred:", err);
           // this.assetDetailsForm.get('assetClass')?.disable();
            this.assetDetailsForm.get('totalCashFlow')?.disable();
            this.assetDetailsForm.get('report')?.disable();
            this.common.loader.next(false);
            this.common.show('error', 'Error occurred while saving the data');
          }
        });
    }

    onMakeAsset() {
      this.common.loader.next(true);
      this.ltpNum = this.capexFormData.ltpNumber;
    
     // this.assetDetailsForm.get('assetClass')?.enable();
      this.assetDetailsForm.get('totalCashFlow')?.enable();
      this.assetDetailsForm.get('report')?.enable();
    
      const payload = {
        ltpNumber: this.ltpNum,
        version: this.capexFormData.version,
        por: this.capexFormData.POR,
        porStatus: 'Active - Asset Setup',
        assetDetails: {
          ...this.assetDetailsForm.getRawValue()
        }
      };
    
      console.log(payload);
      this.common.postCapexAPI('ltp-por/save/asset-setup', payload).subscribe({
        next: (data) => {
        //  this.assetDetailsForm.get('assetClass')?.disable();
          this.assetDetailsForm.get('totalCashFlow')?.disable();
          this.assetDetailsForm.get('report')?.disable();
          this.common.loader.next(false);
          this.common.show('success', 'Make Asset submitted successfully');
          this.isAnalystButtonVisible = false;
          this.isMakeAssetButtonVisible = false; 
          this.porStatusUpdated.emit('Active - Asset Setup');
          console.log(data);
        },
        error: (err) => {
          console.error("Error occurred during asset setup:", err);
        //  this.assetDetailsForm.get('assetClass')?.disable();
          this.assetDetailsForm.get('totalCashFlow')?.disable();
          this.assetDetailsForm.get('report')?.disable();
          this.common.loader.next(false);
          this.common.show('error', 'Error occurred while saving the data');
        }
      });
    }

    submit(){
      this.common.loader.next(true);
      this.ltpNum = this.capexFormData.ltpNumber;
    
    //  this.assetDetailsForm.get('assetClass')?.enable();
      this.assetDetailsForm.get('totalCashFlow')?.enable();
      this.assetDetailsForm.get('report')?.enable();
    
      const payload = {
        ltpNumber: this.ltpNum,
        version: this.capexFormData.version,
        por: this.capexFormData.POR,
        porStatus: 'Complete',
        assetDetails: {
          ...this.assetDetailsForm.value
        }
      };
    
      console.log(payload);
      this.common.postCapexAPI('ltp-por/save/asset-setup', payload).subscribe({
        next: (data) => {
        //  this.assetDetailsForm.get('assetClass')?.disable();
          this.assetDetailsForm.get('totalCashFlow')?.disable();
          this.assetDetailsForm.get('report')?.disable();
          this.common.loader.next(false);
          this.common.show('success', 'submitted successfully');

          this.porStatusUpdated.emit('Complete');
          console.log(data);
        },
        error: (err) => {
          console.error("Error occurred during asset setup:", err);
        //  this.assetDetailsForm.get('assetClass')?.disable();
          this.assetDetailsForm.get('totalCashFlow')?.disable();
          this.assetDetailsForm.get('report')?.disable();
          this.common.loader.next(false);
          this.common.show('error', 'Error occurred while saving the data');
        }
      });

    }

    ngOnDestroy(): void {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    preventEnter(event: KeyboardEvent) {
      if (event.key === 'Enter') {
          event.preventDefault(); // Prevent the default action of Enter key
      }
  }
}

interface AssetDetails {
  deprCostCenter: string;
  deprCostObjManager: string;
  assetClass2: string;
  assetClassDescription: string;
  building: string;
  labfabInstallLocation: string;
  toolOwner: string;
  assetUsefulLife2: string;
  assetDescription: string;
  assetValue: string;
  assetNumber: string
  assetComments: string;
}