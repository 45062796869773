import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { MatSelectChange } from '@angular/material/select';

interface Column {
  header: string;
  expandColumns: string[];
  isExtendable?: boolean;
}

interface Element {
  [key: string]: any; // Using index signature for dynamic keys
}

@Component({
  selector: 'app-capex-search',
  templateUrl: './capex-search.component.html',
  styleUrls: ['./capex-search.component.scss']
})
export class CapexSearchComponent {
  isadmin!: boolean;
  isplanner!: boolean;
  requestTypeOptions: string[] = ['LTP (>1 year out)', 'POR(Current Fiscal Year)', 'Expense (CAP SH Review)'];
  sectionOptions = ['PWP Ops', 'PSI', 'CNPI', 'BPI', 'PFPP', 'MEMS'];
  // plannerOptions: string[] = ['krista.lively@hp.com', 'reshu.jain@hp.com', 'michael.moffit@hp.com'];
  plannerOptions: string[] = [];
  //ltpPorNumberList: string[] = ['1111', '1122', '1133', '1144', '1155', '1166', '1177', '1188', '1199', '1200'];
  ltpPorNumberList: string[] = [];
  searchRecords: FormGroup;
  searchResults: any[] = [];
  filteredLTPPORNumber!: Observable<string[]>;
  status: any[] = [];
  tableHeaderArray: any = [];
  capexSearchDetails: any;
  showGrid!: boolean;
  columnOrder: any[] = [];
  dataSource: any;
  plannerDataSource = [];
  searchClicked = false;
  filters: any;
  initiallyVisibleColumns: string[] = [];
  columnVisibility: { [key: string]: boolean } = {};
  columnMapping: { [key: string]: string[] } = {};
  headers: string[] = [];
  loadingExport: boolean = false;
  dataForWorksheet: any;
  ExrequestType!: string;
  depccmanager!: string;
  approvedBy: string = '';
  por_number: string = '';
  viewStatusOfDepreciation_Approval: any = [];
  ApprovalComment:string = '';
  ApprovalStatus:string = '';
  ApprovalDate:string = '';
  ApprovedByEmail:string = '';

  columnHeaders: Column[] = [
    {
      header: 'Action',
      expandColumns: [''],
      isExtendable: false
    },
    {
      header: 'LTP#',
      expandColumns: [],
      isExtendable: false
    },
    {
      header: 'LTP Status',
      expandColumns: [],
      isExtendable: false
    },
    {
      header: 'POR#',
      expandColumns: []
    },
    {
      header: 'POR Status',
      expandColumns: []
    },
    {
      header: 'CAP#',
      expandColumns: []
    },
    {
      header: 'CAP Status',
      expandColumns: []
    },
    // {
    //   header: 'AMR#',
    //   expandColumns: []
    // },
    {
      header: 'WBS#',
      expandColumns: []
    },
    {
      header: 'WBS',
      expandColumns: ['WBS Status', 'WBS Amount', 'Receiving WBS#', 'Receiving WBS Status', 'Receiving WBS Amount', 'Project Start Date (Date WBS Received)'],
      isExtendable: true
    },
    {
      header: 'Site',
      expandColumns: []
    },
    {
      header: 'Building',
      expandColumns: []
    },
    {
      header: 'LabFab Install Location',
      expandColumns: []
    },
    {
      header: 'Company Code',
      expandColumns: []
    },
    {
      header: 'Region',
      expandColumns: []
    },
    {
      header: 'Comments',
      expandColumns: []
    },
    {
      header: 'Actual Commit Date',
      expandColumns: []
    },
    // {
    //   header: 'WBS',
    //   expandColumns: ['WBS#', 'WBS Status', 'WBS Amount', 'Receiving WBS#', 'Receiving WBS Status', 'Receiving WBS Amount', 'Project Start Date (Date WBS Received)'],
    //   isExtendable: true
    // },
    {
      header: 'POR Info',
      expandColumns: ['Tool Name/Description', 'Project Type', 'Planner', 'Project Manager Email', 'Justification', 'Impact of Not Doing', 'Program Driver', 'Category', 'Rollup', 'Businesss Unit', 'Build Site'],
      isExtendable: true
    },
    {
      header: 'Depreciation',
      expandColumns: ['Depreciation Cost Center Manager', 'Depreciation Cost Center', 'Depreciation Org - L2', 'Depreciation  Org - L3', 'Depreciation Org - L4', 'Depreciation Section', 'Depreciation MRU', 'Depreciation Sub-MRU'],
      isExtendable: true
    },
    {
      header: 'Asset',
      expandColumns: ['# of Assets', 'Asset Useful Life (yrs)'],
      isExtendable: true
    },
    {
      header: 'Commit',
      expandColumns: ['Commit Cost Center', 'Commit Org - L2', 'Commit Org - L3', 'Commit Org - L4', 'Commit Section', 'Commit MRU', 'Commit Sub-MRU'],
      isExtendable: true
    },
    {
      header: 'Schedule Information',
      expandColumns: ['Down Payment (Commit) Qtr', 'Delivery (RTE) Qtr', 'Acceptance (RTO) Qtr', 'Final Payment  Qtr', 'Asset Setup Due Date (2 qtrs from RTO)', 'Current Asset Setup Due Date (adjusted from the Original)', 'Actual Asset Setup Date'],
      isExtendable: true
    },
    {
      header: 'Finance Information',
      expandColumns: ['Item Cash Flow Method', 'Item Cash Flow PO Terms', 'NRE Cash Flow Method', 'NRE Cash Flow Parameters', 'Total Cost ($K)', 'NRE Total Cost ($K)', 'Additional Cost ($K)', 'Item Cost ($K)', 'Hookup - Capitalized Install Costs ($K)', 'Hookup - Expensed Install Costs ($K)', 'Eng NRE Cost Location', 'Engr NRE (FTE Days)', 'Eng NRE Cost ($K)', 'Tech NRE Cost Location', 'Tech NRE (FTE Days)', 'Tech NRE Cost ($K)', 'Months Overdue', 'Est TTL Spend', 'PO Commits', 'PO Invoiced', 'Total JV', 'Total Cash Flow', 'Recon Total'],
      isExtendable: true
    },
    {
      header: 'NRE',
      expandColumns: ['NRE Charged', 'Remaining NRE', 'Est Remaining Cash Flow'],
      isExtendable: true
    },
    {
      header: 'Quater',
      expandColumns: ['Q1', 'Q2', 'Q3', 'Q4', 'Current Qtr NRE', 'Actual QTD (23Q1)', '23Q1', '23Q2', '23Q3', '23Q4'],
      isExtendable: true
    },
    // Add other columns similarly
  ];

  tableColumns: string[] = []
  expandedColumns: { [key: string]: boolean } = {};
  columnNames!: string[];
  buildings: any;
  loading: boolean = false;
  allSites: any[] = [];

  // @ViewChild(MatCheckbox) headerCheckBox!: MatCheckbox;
  @ViewChild('approvalDialog') approvalDialog!: TemplateRef<any>;
  constructor(private common: CommonService, private fb: FormBuilder, private router: Router, private http: HttpClient, private cdr: ChangeDetectorRef, private dialog: MatDialog) {
    this.common.bannerData.next({ title: 'CAPEX', subtitle: 'Search LTP/POR Records' })
    this.searchRecords = this.fb.group({
      requestType: [''],
      status: [''],
      ltpPorNumber: [''],
      planner: [''],
      section: [''],
      requestingSite: [''],
      projectManager: [''],
      commitManager: [''],
      depreciationManager: [''],
      commitQuarter: [''],
      requestDate: ['']
    })
  }

  ngOnInit() {
    this.isadmin = (this.common.getUserRole()) == 'Admin';
    this.isplanner = (this.common.getUserRole()) == 'Planner';
    this.showGrid = false;
    this.capexSearchDetails = null;
    this.expandedColumns = {};

    //dont need datakey for requestType and status
    this.filters = [
      { filterKey: 'requestType' },
      { filterKey: 'status' },
      { filterKey: 'ltpPorNumber', dataKey: 'LTP#' },
      { filterKey: 'planner', dataKey: 'Planner' },
      { filterKey: 'section', dataKey: 'Section' },
      { filterKey: 'requestingSite', dataKey: 'Site' },
      { filterKey: 'projectManager', dataKey: 'Project Manager Email' },
      { filterKey: 'commitManager', dataKey: 'Commit Cost Center Manager' },
      { filterKey: 'depreciationManager', dataKey: 'Depreciation Cost Center Manager' },
      { filterKey: 'commitQuarter' },
      { filterKey: 'requestDate' }
    ]


    this.tableColumns = ['select']; // Select All Checkbox Column
    this.columnHeaders.forEach(column => {
      this.expandedColumns[column.header] = false;
    });

    this.columnNames = this.columnHeaders.map(column => column.header);
    // end

    this.common.getAPI('amd-get-labsite').subscribe({
      next: (
        (data) => {
          this.buildings = data;
          // console.log(this.buildings);
          this.loading = false;
          for (let i = 0; i < this.buildings?.length; i++) {
            let tmp = this.buildings[i]['Location'];
            this.allSites.push(tmp);
          }
          this.allSites = this.allSites?.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          this.allSites.sort();
        }),
      error: (
        (error) => {
          console.error("Error occurred:", error);
          alert("Please connect with Asset Admin");
        })
    })

    this.common.getCapexAPI('capex-request-role').subscribe({
      next: (
        (data) => {
         // this.financeOptions = data['Finance'].filter((a: any) => a['Capex_Request_Role'] == 'Finance' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
          this.plannerOptions = data['Planner'].filter((a: any) => a['Capex_Request_Role'] == 'Planner' && a['Active'] == true).map((a: any) => a['Capex_Request_Role_Name']);
        }),
      error: (
        (err) => {
          console.error("Error occurred:", err);
        })
    });
  }

  selectAllNewToggle(event: any) {
    // Implement select all logic here
  }

  submitNewRequest() {
    this.router.navigate(['/capex/capexDetails'], { state: { action: 'Submit' } });
  }

  convertRecords() {

  }

  showLtpPorNumber() {
    this.filteredLTPPORNumber = this.searchRecords.controls['ltpPorNumber'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ltpPorNumberList.filter(option => option.toLowerCase().includes(filterValue));
  }

  onRequestOptionSelected(selectedValue: string) {
    console.log("Option Selected", selectedValue);
    this.ExrequestType = selectedValue; 
    if (selectedValue == 'POR(Current Fiscal Year)') {
      this.status = ['In Queue', 'Active'];


    }
    else if (selectedValue == 'LTP (>1 year out)') {
      this.status = ['Converted', 'In Queue', 'Draft'];

    }

  }









  valueChange(formName: any) {
    console.log("Form Value", formName);
    // Clear other fields
    this.filters.forEach((otherFilter: any) => {
      if (otherFilter.filterKey != 'status' && otherFilter.filterKey != 'ltpPorNumber') {
        this.status = [];
        this.ltpPorNumberList = [];
      }
      if (otherFilter.filterKey != formName) {
        this.searchRecords.controls[otherFilter.filterKey].setValue('');
      }
    })
  }


  onSearch() {
    this.common.loader.next(true);
    this.common.postCapexAPI('search', this.searchRecords.value).subscribe({
      next: (data) => {
        const output = data.message;
        this.loading = true;
        this.capexSearchDetails = null;
        this.showGrid = false;
        this.searchClicked = true;    
        if (Array.isArray(output) || (typeof output === 'object' && output !== null)) {
          this.dataSource = this.mapResponseToColumns(Array.isArray(output) ? output : [output]);
          this.searchResults = Array.isArray(output) ? output : [output];    
          // console.log(this.searchResults);
          this.common.loader.next(false);
        } else {
          console.error('Unexpected output format:', output);
        }
      },
      error: (err) => {
        console.error("Error occurred:", err);
      }
    });
    
  }


  mapResponseToColumns(data: Element[]): Element[] {
    return data.map(item => ({
      'LTP#': item['LTP'], // Adjust these keys as necessary
      'LTP Status': item['LTP_Status'],
      'POR#': item['POR'],
      'CAP#': item['CAP'],
      'CAP Status': item['CAP_Status'],
      'POR Status': item['POR_Status'],
      'Site': item['Site'],
      'Building': item['Building'],
      'LabFab Install Location': item['LabFab_Install_Location'],
      'Company Code': item['Company_Code'],
      'Region': item['Region'],
      'Comments': item['Comments'],
      'Actual Commit Date': item['Actual_Commit_Date'],
      'Project Type': item['Project_Type'],
      'Tool Name/Description': item['Tool_Name_Description'],
      'Project Manager Email': item['Project_Manager_Email'],
      'Planner': item['Planner'],
      'Justification': item['Justification'],
      'Impact of Not Doing': item['Impact_of_Not_Doing'],
      'Program Driver': item['Program_Driver'],
      'Category': item['Category'],
      'Rollup': item['Rollup'],
      'Businesss Unit': item['Business_Unit'],
      'Build Site': item['Build_Site_or_shipping_to_another_site'],
      'Depreciation Cost Center Manager': item['Depreciation_Cost_Center_Manager'],
      'Depreciation Cost Center': item['Depreciation_Cost_Center'],
      'Depreciation Org - L2': item['Depreciation_Org_L2'],
      'Depreciation Org - L3': item['Depreciation_Org_L3'],
      'Depreciation Org - L4': item['Depreciation_Org_L4'],
      'Depreciation Section': item['Depreciation_Section'],
      'Depreciation MRU': item['Depreciation_MRU'],
      'Depreciation Sub-MRU': item['Depreciation_Sub_MRU'],
      '# of Assets': item['Number_of_Assets'],
      '23Q1': item['23Q1'],
      '23Q2': item['23Q2'],
      '23Q3': item['23Q3'],
      '23Q4': item['23Q4'],
      'Actual QTD (23Q1)': item['Actual_QTD_23Q1'],
      'Current_Qtr_NRE': item['Current_Qtr_NRE'],
      'Q1': item['Q1'],
      'Q2': item['Q2'],
      'Q3': item['Q3'],
      'Q4': item['Q4'],
      'NRE Charged': item['NRE_Charged'],
      'Remaining NRE': item['Remaining_NRE'],
      'Est Remaining Cash Flow': item['Est_Remaining_Cash_Flow'],
      'Item Cash Flow Method': item['Item_Cash_Flow_Method'],
      'Item Cash Flow PO Terms': item['Item_Cash_Flow_PO_Terms'],
      'NRE Cash Flow Method': item['NRE_Cash_Flow_Method'],
      'NRE Cash Flow Parameters': item['NRE_Cash_Flow_Parameters'],
      'Total Cost ($K)': item['Total_Cost_$K'],
      'NRE Total Cost ($K)': item['NRE_Total_Cost_$K'],
      'Additional Cost ($K)': item['Additional_Cost_$K'],
      'Item Cost ($K)': item['Item_Cost_$K'],
      'Hookup - Capitalized Install Costs ($K)': item['Hookup_Capitalized_Install_Costs_$K'],
      'Hookup - Expensed Install Costs ($K)': item['Hookup_Expensed_Install_Costs_$K'],
      'Eng NRE Cost Location': item['Eng_NRE_Cost_Location'],
      'Engr NRE (FTE Days)': item['Engr_NRE_FTE_Days'],
      'Eng NRE Cost ($K)': item['Eng NRE Cost ($K)'],
      'Tech NRE Cost Location': item['Tech_NRE_Cost_Location'],
      'Tech NRE (FTE Days)': item['Tech_NRE_FTE_Days'],
      'Tech NRE Cost ($K)': item['Tech_NRE_Cost_$K'],
      'Months Overdue': item['Months_Overdue'],
      'Est TTL Spend': item['Est_TTL_Spend'],
      'PO Commits': item['PO_Commits'],
      'PO Invoiced': item['PO_Invoiced'],
      'Total JV': item['Total_JV'],
      'Total Cash Flow': item['Total_Cash_Flow'],
      'Recon Total': item['Recon_Total'],
      'Down Payment (Commit) Qtr': item['Down_Payment_Commit_Qtr'],
      'Delivery (RTE) Qtr': item['Delivery_RTE_Qtr'],
      'Acceptance (RTO) Qtr': item['Acceptance_RTO_Qtr'],
      'Final Payment Qtr': item['Final_Payment_Qtr'],
      'Asset Setup Due Date (2 qtrs from RTO)': item['Asset_Setup_Due_Date_2_qtrs_from_RTO'],
      'Current Asset Setup Due Date (adjusted from the Original)': item['Current_Asset_Setup_Due_Date_adjusted_from_the_Original'],
      'Actual Asset Setup Date': item['Actual_Asset_Setup_Date'],
      'Commit Cost Center': item['Commit_Cost_Center'],
      'Commit Org - L2': item['Commit_Org_L2'],
      'Commit Org - L3': item['Commit_Org_L3'],
      'Commit Org - L4': item['Commit_Org_L4'],
      'Commit Section': item['Commit_Section'],
      'Commit MRU': item['Commit_MRU'],
      'Commit Sub-MRU': item['Commit_Sub_MRU'],
      'Asset Useful Life (yrs)': item['Asset_Useful_Life_in_yrs'],
      'WBS#': item['WBS'],
      'WBS Status': item['WBS_Status'],
      'WBS Amount': item['WBS_Amount'],
      'Receiving WBS#': item['Receiving_WBS'],
      'Receiving WBS Status': item['Receiving_WBS_Status'],
      'Receiving WBS Amount': item['Receiving_WBS_Amount'],
      'Project Start Date (Date WBS Received)': item['Project_Start_Date_or_Date_WBS_Received'], 
    }));
  }


  toggleColumn1(column: string) {
    this.expandedColumns[column] = !this.expandedColumns[column];
  }

  toggleColumn(column: string): void {
    // this.columnVisibility[column] = !this.columnVisibility[column];
    switch (column) {
      case 'LTP#':
        this.columnVisibility['LTP Status'] = !this.columnVisibility['LTP Status'];
        break;

      case 'POR#':
        this.columnVisibility['POR Status'] = !this.columnVisibility['POR Status'];
        break;

      case 'WBS#':
        this.columnVisibility['WBS Status'] = !this.columnVisibility['WBS Status'];
        this.columnVisibility['WBS Amount'] = !this.columnVisibility['WBS Amount'];
        this.columnVisibility['Project Start Date'] = !this.columnVisibility['Project Start Date'];
        break;

      default:
        break;
    }
  }


  collapse() {
    //this.displayedColumns = [...this.initiallyVisibleColumns]
    for (let key in this.expandedColumns) {
      this.expandedColumns[key] = false;
    }
  }

  recordHistory(row: any) {
    const ltp = row['LTP#'];
    // const reqNum = row['LTP#'];
    const ltpStatus = row['LTP Status'];
    //this.router.navigate(['/capex/capexDetails'], { state: { ltp, ltpStatus} });

    // this.router.navigate(['/capex/capexDetails'], { queryParams: { reqNum } });

    this.router.navigate(['/capex/capexDetails'], { 
        queryParams: { reqNum: ltp }
        // state: { ltp, ltpStatus }
      });
  }

  convertRecordsLTPtoPROR(row: any) {
  const ltpNumber = row['LTP#'];

  // Find the specific record from the search results
  const ltpRecord = this.searchResults?.find(item => item['LTP'] === ltpNumber);
  console.log(ltpRecord);

  // Configure the dialog
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = false;
  dialogConfig.width = '100%';
  dialogConfig.data = {
    title: `Confirm Conversion for LTP# ${ltpNumber}`,
    message: `Are you sure you want to convert LTP# ${ltpNumber} to POR?`
  };

  // Open the dialog
  const dialogRef = this.dialog.open(DialogBoxComponent, dialogConfig);

  // Handle the dialog result
  dialogRef.afterClosed().subscribe((result) => {
    if (result === 'Ok') {
      // User confirmed the action
      this.common.loader.next(true);

      this.common.getCapexAPI(`ltp-por/convert-ltp-to-por?ltp=${ltpNumber}`).subscribe({
        next: (data) => {
          console.log('Conversion successful:', data);
          const porNumber = data.message;
          this.common.show('success', `LTP# ${ltpNumber} converted successfully!`);
          this.router.navigate(['/capex/capexSearch']);
          this.common.loader.next(false);

          const estimatedProjectEndQtr = this.common.getQuarterFromDate(ltpRecord.Estimated_Project_End_Qtr);

          // Call the notification API to send email to AMD Admin
          const notificationBody = {
            notificationType: 'POR Generated',
            ltp: ltpNumber,
            por: porNumber,
            planner: ltpRecord.Planner,
            projectManager: ltpRecord.Project_Manager_Email,
            toolDescription: ltpRecord.Tool_Name_Description,
            commitQuarter: ltpRecord.Down_Payment_Commit_Qtr,
            // building: ltpRecord.Building,
            // labFabInstallLocation: ltpRecord.LabFab_Install_Location,
             commitManager: ltpRecord.Commit_Cost_Center_Manager,
             estimatedProjectEnd: estimatedProjectEndQtr,
             depreciationManager: ltpRecord.Depreciation_Cost_Center_Manager,
            // depreciationStartDate: ltpRecord.Depreciation_Start_Date,
            estimatedDepreciationCost: ltpRecord.Estimated_Quarterly_Depreciation,
            assets: ltpRecord.Number_of_Assets,
            assetLife: ltpRecord.Asset_Useful_Life_in_yrs,
            totalCost: ltpRecord.Total_Cost_$K,
            // depreciationSubMRU: ltpRecord.Depreciation_Sub_MRU,
            // commitSubMRU: ltpRecord.Commit_Sub_MRU
          };
          console.log(notificationBody);
          this.common.postCapexAPI('capex-notify', notificationBody).subscribe({
            next: (data) => {
              this.common.show('success', 'Email notification sent successfully.');
            },
            error: (err) => {
              console.error("Error sending email notification:", err);
              this.common.show('error', 'Failed to send email notification.');
            }
          });

          this.onSearch();
        },
        error: (err) => {
          this.common.loader.next(false);
          this.common.show('error', `Error occurred while converting LTP# ${ltpNumber}`);
          console.error("Error occurred:", err.message);
        }
      });
    } else {
      // User canceled the action
      console.log('Conversion canceled. No changes made.');
      this.common.show('info', `No changes made to LTP# ${ltpNumber}`);
    }
  });
}

  secondAction(row: any) {
    // Implement your second action here...
  }

  trackApproval(row:any){
    this.por_number = row['POR#'];
    const record = this.searchResults?.find(item => item['POR'] == this.por_number);
    if (record.Depreciation_Manager_Approval) {
    this.ApprovalComment = record.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Comment;
    this.ApprovalDate = record?.Depreciation_Manager_Approval?.Depreciation_Manager_Approval_Date?.split('T')[0] ?? null;
    this.ApprovalStatus = record.Depreciation_Manager_Approval.Depreciation_Manager_Approval_Status;
    this.ApprovedByEmail = record.Depreciation_Manager_Approval.Depreciation_Manager_Email;
    }
    //this.approvedBy = atob(this.common.currentUser);
    this.depccmanager = row['Depreciation Cost Center Manager'] ? row['Depreciation Cost Center Manager'] + '@hp.com' : '';
    this.dialog.open(this.approvalDialog, { width: '800px' });
    this.viewStatusOfDepreciation_Approval?.push({"Name": `email_notifications/Depreciation_Approval_${this.por_number}.html`,
    "Path": 'capex'})
  }
  

  closeDialog() {
    this.dialog.closeAll(); 
    this.ApprovalComment = '';
    this.ApprovalDate = '';
    this.ApprovalStatus = '';
    this.ApprovedByEmail = ''; 
    this.depccmanager = ''; 
    this.viewStatusOfDepreciation_Approval = [];
  }

  export(): void {
    console.log('exported data ');
   if (!this.searchResults || this.searchResults.length === 0) {
     alert('No data available to export');
     return;
   }
   const dataForExport = this.mapResponseToColumns(this.searchResults);
   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataForExport);

   const workbook: XLSX.WorkBook = {
     Sheets: { 'CAPEX Search Results': worksheet },
     SheetNames: ['CAPEX Search Results']
   };

   XLSX.writeFile(workbook, 'CapexSearchResults.xlsx');
 }

}
