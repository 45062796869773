<form [formGroup]="capInfoForm">
  <mat-card>
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>POR<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="PORNumber" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6" *ngIf="this.capInfoForm.value.focusAreaName">
                  <mat-form-field class="w-100">
                      <mat-label>Focus Area Name<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="focusAreaName" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6" *ngIf="!this.capInfoForm.value.focusAreaName">
                  <mat-form-field class="w-100">
                      <mat-label>Action Plan<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="actionPlan" placeholder="" readonly>
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Site<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="InitiateSite" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Build Option<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="BuildOption" placeholder="" readonly>
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Purpose<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="Purpose" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Proposed Location<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="InitiateLabFab" placeholder="" readonly>
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Tool History<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="ToolHistory" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Tool Condition<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="ToolCondition" placeholder="" readonly>
                  </mat-form-field>
              </div>
          </div>

          <div class="row">
              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Tool Name<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="ToolName" placeholder="" readonly>
                  </mat-form-field>
              </div>

              <div class="col-md-6">
                  <mat-form-field class="w-100">
                      <mat-label>Current Status<span style="color: #FF0000;" aria-required="true">*</span></mat-label>
                      <input matInput type="text" formControlName="PORStatus" placeholder="" readonly>
                  </mat-form-field>
              </div>
          </div>
      </div>
  </mat-card>

  <div class="col-md-12" *ngIf="loading">
    <mat-spinner diameter="20"></mat-spinner>
    <span>Loading Data...</span>
  </div>
  
</form>
