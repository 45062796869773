import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DialogBoxComponent } from 'src/app/modules/dialog-box/dialog-box.component';
import { CommonService } from 'src/app/services/common.service';
import * as XLSX from 'xlsx';
import { EditOrganizationComponent } from './edit-organization/edit-organization.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  constructor(private common: CommonService, private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditOrganizationComponent>,
    private cdr: ChangeDetectorRef,
    private router: Router, private toastr: ToastrService, private dialog: MatDialog) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { action: string };
    this.common.bannerData.next({ title: 'Organization', subtitle: 'Organization Hierarchy' })
  }

  @ViewChild('Import_Bulk_Cost_Center_Code')
  Import_Bulk_Cost_Center_Code!: ElementRef;

  @ViewChild('Import_Bulk_Program_Drivers')
  Import_Bulk_Program_Drivers!: ElementRef;

  assetDetails: any;
  adminAssetData: any;
  flag = false;
  assetdata = [];
  num = 0;
  count: number = 0;
  locloading = true;
  loading = false;
  isadmin: boolean = false;
  excelData: any = [];
  progressUpload = false;
  chunkCount: number = 0;
  chunkSaved: boolean = false;
  chunkSize: number = 200;
  progress: any = 0;
  chunkCompleted = 0;
  programDriverData:any;
  costCenterData:any;
  startedImporting = false;
  subscriptions: Subscription[] = [];
  bulkCost_Center_Owner_Headers: any = ['Cost Center Code', 'Cost Center Owner', 'Cost Center Description', 'Company Code', 'Region', 'MRU Code'];
  bulk_Program_Drivers_Headers:any = ['Level3', 'ProgramDriver', 'Category', 'RollUp', 'BusinessUnit', 'EngrNRELoc', 'TechNRELoc', 'Inactive']
  userRole = this.common.getUserRole();
  isCCActive:boolean = true;

  noRecordFound: boolean = false;
  dataSource: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = ['Edit', 'CostCenterCode', 'CostCenterDescription', 'CostCenterOwner', 'Level2', 'Level3', 'Level4', 'Section', 'MRUCode', 'SubMRU', 'CompanyCode', 'Region', 'ExpType',
    'Commit', 'Depr', 'Inactive'];  


  ngOnInit() {
    this.assetDetails = null
    this.common.loader.next(true);
    // if (sessionStorage.getItem('flag') == 'true') {
    //   this.locloading = false
    // }
    // sessionStorage.removeItem('flag');
    this.isadmin = (this.common.getUserRole() == 'Admin');
    this.getTableData();
    this.ProgramDriver();
  }
  getTableData() {
    this.common.loader.next(true);
    this.subscriptions.push(
      this.common.getAPI('amd-export-all-cost-center-code?start=0&end=2000').subscribe({
        next: (
          (res) => {
            if (!res || res.errorMessage) {
              this.noRecordFound = true;
              return;
            } else {
              this.noRecordFound = false;
              this.common.loader.next(false);
              this.costCenterData = res['body'];
              this.dataSource = new MatTableDataSource(this.costCenterData);
              this.dataSource.paginator = this.paginator;
            }
          }),
        error: (
          error => {
            if ('message' in error.error) {
              this.toastr.error(error.error['message']);
            }
            else {
              this.toastr.error('Internal Server Error, please logout and try again. If issue persists, contact Admin')
            }
            console.log(error);
          })
      })
    );
  }

  getCostCenter(){
    if (!this.costCenterData) {
      // Handle the case when programDriverData is not available
      console.error('Cost Center data is not loaded or No record found');
      return;
    }
    this.common.loader.next(true);
    this.isCCActive = !this.isCCActive;
    this.displayedColumns =  ['Edit', 'CostCenterCode', 'CostCenterDescription', 'CostCenterOwner', 'Level2', 'Level3', 'Level4', 'Section', 'MRUCode', 'SubMRU', 'CompanyCode', 'Region', 'ExpType',
        'Commit', 'Depr', 'Inactive'];  
    this.noRecordFound = this.dataSource.data.length === 0;
    this.dataSource = new MatTableDataSource(this.costCenterData);
    this.common.loader.next(false);    
    this.dataSource.paginator = this.paginator;

  }

  ProgramDriver(){
    this.common.getCapexAPI('program-drivers').subscribe({
      next: (data) => {
        this.programDriverData = data['message'];
        console.log(data.message);
      },
      error: (err) => {
        // Handle the error
        console.error("Error occurred:", err);
        // You might want to display an error message to the user here
      }
    });
  }

  showProgramDriver(){
    if (!this.programDriverData) {
      // Handle the case when programDriverData is not available
      console.error('Program driver data is not loaded or No record found');
      return;
    }
    this.common.loader.next(true);
    this.isCCActive = !this.isCCActive;
    this.displayedColumns = ['Level3', 'ProgramDriver', 'Category', 'RollUp', 'BusinessUnit', 'EngrNRELoc', 'TechNRELoc', 'Inactive'];
    this.noRecordFound = this.dataSource.data.length === 0;
    this.dataSource = new MatTableDataSource(this.programDriverData);
    this.common.loader.next(false);    
    this.dataSource.paginator = this.paginator;
  }

  //Bulk import uploading Cost Center Code,Cost Object manager, Owners STARTS
  importBulkCostManagers(evt: any) {

    console.log("Bulk import uploading Cost Center Code")
    this.excelData = []
    this.startedImporting = true;
    // console.log("insert to S4 sort key")
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target?.files[0]?.name && target?.files[0]?.name != 'AMD_Bulk_Cost_Center_Owner.xlsx') {
      this.openDialogMessage('Uploading file must be "AMD_Bulk_Cost_Center_Owner.xlsx"', '');
      this.resetFileUploader();
      return
    }

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.loading = true
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      console.log(wb.SheetNames);
      let length = wb.SheetNames.length;
      // console.log(length);
      const data = reader.result;
      let jsonData = null;
      // var sheet1 = wb.SheetNames[1];
      jsonData = wb.SheetNames.reduce((initial, name) => {
        console.log(name);
        if (name == "AMD Bulk Cost Center Code") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});

      let userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++
        this.excelData[i]['userName'] = userName;
        let region = (this.excelData[i]['Cost Center Code']).substring(0, 2)
        if (['ES', 'IL', 'DE'].includes(region)) {
          this.excelData[i]['Region'] = 'EMEA'
        }
        if (['IN', 'SG', 'MY'].includes(region)) {
          this.excelData[i]['Region'] = 'APJ'
        }
        if (['US', 'PR'].includes(region)) {
          this.excelData[i]['Region'] = 'AMS'
        }

        replace(this.excelData[i])
        function replace(myObj: any) {
          Object.keys(myObj).forEach(function (key) {

            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                //console.log(myObj[key].getTimezoneOffset());
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                //myObj[key] = String((myObj[key].getUTCMonth()+1)+"/"+myObj[key].getUTCDate()+"/"+myObj[key].getUTCFullYear());
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      console.log(this.excelData);
      this.chunkSize = 0;
      this.chunkCount = 0;
      this.progress = 0;
      this.chunkSize = this.excelData.length;
      //console.log(this.chunkSize%200);
      this.chunkCount = (this.chunkSize % 100 === 0 ?
        (this.chunkSize / 100) :
        (Math.floor(this.chunkSize / 100) + 1));
      this.chunkSaved = false;
      this.callBulkCostMangers(0, 99);
    }
    reader.readAsBinaryString(target.files[0]);
  }

  importBulkProgramDrivers(evt: any) {

    console.log("Bulk import uploading Program Drivers")
    this.excelData = []
    this.startedImporting = true;
    // console.log("insert to S4 sort key")
    const target: DataTransfer = <DataTransfer>(evt.target);

    if (target?.files[0]?.name && target?.files[0]?.name != 'AMD_Bulk_Program_Drivers.xlsx') {
      this.openDialogMessage('Uploading file must be "AMD_Bulk_Program_Drivers.xlsx"', '');
      this.resetFileUploaderPD();
      return
    }

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.loading = true
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      console.log(wb.SheetNames);
      let length = wb.SheetNames.length;
      // console.log(length);
      const data = reader.result;
      let jsonData = null;
      // var sheet1 = wb.SheetNames[1];
      jsonData = wb.SheetNames.reduce((initial, name) => {
        console.log(name);
        if (name == "AMD Bulk Program Drivers") {
          const sheet = wb.Sheets[name];
          this.excelData = this.excelData.concat(XLSX.utils.sheet_to_json(sheet, { range: 1, defval: "null" }));
        }
        return initial;
      }, {});

      let userName = atob(this.common.currentUser);
      for (let i in this.excelData) {
        this.num++
        this.excelData[i]['userName'] = userName;

        replace(this.excelData[i])
        function replace(myObj: any) {
          Object.keys(myObj).forEach(function (key) {

            if (myObj[key] instanceof Date) {
              if (myObj[key]) {
                let date = new Date(myObj[key]);
                //console.log(myObj[key].getTimezoneOffset());
                let offSet = myObj[key].getTimezoneOffset();
                if (offSet > 0) {
                  date = new Date(date.getTime() + (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                else {
                  date = new Date(date.getTime() - (myObj[key].getTimezoneOffset() * 60 * 1000));
                  myObj[key] = String((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
                }
                //myObj[key] = String((myObj[key].getUTCMonth()+1)+"/"+myObj[key].getUTCDate()+"/"+myObj[key].getUTCFullYear());
              }
              else {
                myObj[key] = "";
              }
            }
            else {
              typeof myObj[key] == 'object' ? replace(myObj[key]) : myObj[key] = String(myObj[key]);
            }
          });
        }
      }
      console.log(this.excelData);
      this.chunkSize = 0;
      this.chunkCount = 0;
      this.progress = 0;
      this.chunkSize = this.excelData.length;
      //console.log(this.chunkSize%200);
      this.chunkCount = (this.chunkSize % 100 === 0 ?
        (this.chunkSize / 100) :
        (Math.floor(this.chunkSize / 100) + 1));
      this.chunkSaved = false;
      this.callBulkProgramDrivers(0, 99);
    }
    reader.readAsBinaryString(target.files[0]);
  }

  callBulkCostMangers(start: any, end: any) {
    console.log("startHua");

    if ((this.chunkSize - start) < 100) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      this.subscriptions.push(
        this.common.postAPI('amd-costcenter-costobjectmanager/', param).subscribe({
          next: (
            (data: any) => {
              this.chunkSaved = false;
              if (data['statusCode'] == 200) {
                if (end < this.chunkSize) {
                  this.callBulkCostMangers(start + 100,
                    (this.chunkSize - start) < 100 ? ((this.chunkSize - start) + start) : (end + 100));
                } else {
                  //this.progress = 100;
                  this.startedImporting = false;
                  this.loading = false;
                  //alert('File Imported Sucessfull ');
                  this.openDialogMessage(' File Imported Sucessfull ', '');
                  this.resetFileUploader();

                }
              } else {
                this.loading = false;
                this.resetFileUploader();
                this.openDialogMessage('Failed to import asset', '');

              }

              this.chunkCompleted++;
              console.log("end: " + end, " this.excelData.length: " + this.excelData.length);
              this.progress = (end == 0 ? 100 : ((end / this.excelData.length) * 100).toFixed(2)); //((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
              console.log("chunkCount: " + this.chunkCount);
              console.log("chunksCompleted: " + this.chunkCompleted);
              console.log(this.progress + '%');
              console.log('if exit');
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
      )
    }
    // Bulk import uploading Cost Center Code,Cost Object manager, Owners ENDS
  }

  callBulkProgramDrivers(start: any, end: any) {

    if ((this.chunkSize - start) < 100) {
      end = this.chunkSize;
    }
    if (!this.chunkSaved) {
      this.chunkSaved = true;
      let param = this.excelData.slice(start, end);
      this.subscriptions.push(
        this.common.postCapexAPI('program-drivers', param).subscribe({
          next: (
            (data: any) => {
              this.chunkSaved = false;
              if (data["message"] =="Program Drivers have been updated successfully") {
                console.log("end",end,"ch",this.chunkSize)
                if (end < this.chunkSize) {
                  this.callBulkProgramDrivers(start + 100,
                    (this.chunkSize - start) < 100 ? ((this.chunkSize - start) + start) : (end + 100));
                } else {
                  //this.progress = 100;
                  this.startedImporting = false;
                  this.loading = false;
                  //alert('File Imported Sucessfull ');
                  this.openDialogMessage(' File Imported Sucessfull ', '');
                  this.resetFileUploaderPD();

                }
              } else {
                this.loading = false;
                this.resetFileUploaderPD();
                this.openDialogMessage('Failed to import asset', '');

              }

              this.chunkCompleted++;
              console.log("end: " + end, " this.excelData.length: " + this.excelData.length);
              this.progress = (end == 0 ? 100 : ((end / this.excelData.length) * 100).toFixed(2)); //((this.chunkCompleted / this.chunkCount) * 100).toFixed(2);
              console.log("chunkCount: " + this.chunkCount);
              console.log("chunksCompleted: " + this.chunkCompleted);
              console.log(this.progress + '%');
              console.log('if exit');
            }
          ),
          error: (
            error => {
              this.common.errorHandler(error);
              this.common.loader.next(false);
            }
          )
        })
      )
    }
    // Bulk import uploading Cost Center Code,Cost Object manager, Owners ENDS
  }
  //reset teh input file value
  resetFileUploader() {
    this.Import_Bulk_Cost_Center_Code.nativeElement.value = null;
    this.chunkCompleted = 0;
    this.progress = 0;
    this.chunkCount = 0;
  }

  resetFileUploaderPD() {
    this.Import_Bulk_Program_Drivers.nativeElement.value = null;
    this.chunkCompleted = 0;
    this.progress = 0;
    this.chunkCount = 0;
  }

  //Dialog box messages
  openDialogMessage(message: any, assetID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      message: message,
      tabletemplate: false
    }
    this.dialog.open(DialogBoxComponent, dialogConfig);
  }

  edit(element: any, i:number) {
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      data: {element, i},
      width: '80%',
      maxWidth: '600px',
      height: 'auto', 
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTableData();
      }
    });
  }

  addCostCenter() {
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      width: '80%',
      maxWidth: '600px',
      height: 'auto', // Allow height to be dynamic
      maxHeight: '90vh' // Set max height to ensure it's within the viewport
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTableData();
      }
    });
    
  }

  toggleInactive(element: any, i:number){
    const postData = [];
    postData.push(element)

    this.common.postCapexAPI('program-drivers', postData).subscribe({
      next: (
        (res) => {
          console.log(res); 
          if(element.Inactive=="false" || element.Inactive == false ){
            element.Inactive = true;
      
          }
          else if(element.Inactive=="true" || element.Inactive == true ){
            
            element.Inactive= false;
          } 
          this.toastr.success("Inactive Status Updated Successfully")
  
        }),
      error: (
        error => {
          if ('message' in error.error) {
            this.toastr.error(error.error['message']);
          }
          else {
            this.toastr.error('Internal Server Error, please logout and try again. If issue persists, contact Admin')
          }
          console.log(error);
        })
        
    })
    console.log(element, i);
  }

  addProDriver(){
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      data: { isProDriver: true },
      width: '80%',
      maxWidth: '600px',
      height: 'auto', // Allow height to be dynamic
      maxHeight: '90vh' // Set max height to ensure it's within the viewport
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ProgramDriver();
        this.isCCActive = !this.isCCActive;
        this.showProgramDriver();
      }
        
    });
  }

  

  applyFilter(event: any) {
    let filterValue = event.target.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
